import { useEffect, useState } from 'react'
import { useTokenContract } from '@firestarter-private/firestarter-library'
import { ContractAddress } from '@firestarter-private/firestarter-library/src/types/index'
import { Contract } from 'web3-eth-contract'

import { useIsMounted } from '@hooks/useIsMounted'

export function useDecimals(tokenAddress?: ContractAddress): number {
  const isMountedRef = useIsMounted()
  const [decimals, setDecimals] = useState(18)
  const tokenContract = useTokenContract(tokenAddress)

  useEffect(() => {
    const getDecimals = async () => {
      if (!tokenContract) {
        setDecimals(18)
        return
      }

      const result = await (tokenContract as unknown as Contract).methods
        .decimals()
        .call()
      if (isMountedRef.current) {
        setDecimals(+result)
      }
    }

    getDecimals()
  }, [tokenContract, isMountedRef])

  return decimals
}

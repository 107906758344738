import { useCallback, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { maxUint256String } from '@firestarter-private/firestarter-library/lib/constants/bignumberUtils'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks/useNotify'
import { ContractAddress } from '@firestarter-private/firestarter-library/lib/types/index'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BlockNumber, TransactionReceipt } from 'web3-core'
import { Contract } from 'web3-eth-contract'

import { useTokenContract } from '@contracts/hooks/useTokenContract'

import { useTransactions } from './useTransactions'

export interface IUseApproval {
  allowance: BigNumber
  onApprove: (amount?: string, callbacks?: INotifyTxCallbacks) => Promise<void>
  fetchAllowance: (blockNumber?: BlockNumber) => Promise<void>
}

export function useApproval(
  tokenAddress?: ContractAddress,
  spenderAddress?: ContractAddress
): IUseApproval {
  const { account } = useWeb3React()
  const tokenContract = useTokenContract(tokenAddress)
  const [allowance, setAllowance] = useState<BigNumber>(new BigNumber(0))

  const { callTransaction, sendTransaction } = useTransactions()

  const fetchAllowance = useCallback(
    async (blockNumber?: BlockNumber) => {
      if (!account || !tokenContract || !spenderAddress) {
        setAllowance(new BigNumber(0))
        return
      }

      const res = await callTransaction(
        (tokenContract as Contract)?.methods.allowance(account, spenderAddress),
        blockNumber
      )

      setAllowance(new BigNumber(res))
    },
    [account, tokenContract, spenderAddress]
  )

  useEffect(() => {
    fetchAllowance()
    let timer: undefined | number = undefined
    if (isMobile) {
      timer = setInterval(fetchAllowance, 5000)
    }
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [account, tokenContract, spenderAddress])

  const onApprove = useCallback(
    async (
      amount: string = maxUint256String,
      callbacks: INotifyTxCallbacks = {}
    ) => {
      const receipt = (await sendTransaction(
        (tokenContract as Contract)?.methods.approve(spenderAddress, amount),
        callbacks
      )) as TransactionReceipt

      await fetchAllowance(receipt?.blockNumber)
    },
    [tokenContract, spenderAddress, sendTransaction]
  )

  return {
    allowance,
    onApprove,
    fetchAllowance
  }
}

import React, { useState } from 'react'
import { Container, Image } from 'react-bootstrap'

import { DynamicImage, LaunchModal, SiteButton } from '@/components'

import './LaunchProjectSection.scss'
interface ILaunchProjectSectionProps {}

export const LaunchProjectSection =
  ({}: ILaunchProjectSectionProps): JSX.Element => {
    const [showLaunchModal, setShowLaunchModal] = useState<boolean>(false)
    return (
      <div className="launch-project-section">
        <Container>
          <div className="launch-project-section__wrapper">
            <div className="launch-project-section__title">
              Is your NFT project ready to be launched?
            </div>

            <SiteButton
              size={'large'}
              color={'YELLOW'}
              onClick={() => setShowLaunchModal(true)}
            >
              Apply Here
              <DynamicImage path={'menu-icons/launch.svg'} />
            </SiteButton>
          </div>
        </Container>

        <LaunchModal show={showLaunchModal} setShow={setShowLaunchModal} />
      </div>
    )
  }

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import { ConnectWalletTile } from '@components/index'

// styles
import './Wallet.css'

interface Props {}

export const Wallet = (props: Props) => {
  return (
    <div className="wallet-page page">
      <Container>
        <ConnectWalletTile />
      </Container>
    </div>
  )
}

export default Wallet

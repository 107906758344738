const {
  REACT_APP_SELECTED_NETWORK_ID,
  REACT_APP_ENV,
  REACT_APP_POLYGON_RPC_URL,
  REACT_APP_DOGECHAIN_RPC_URL,
  REACT_APP_ETH_RPC_URL,
  REACT_APP_BNB_RPC_URL
} = process.env

export {
  REACT_APP_SELECTED_NETWORK_ID,
  REACT_APP_ENV,
  REACT_APP_POLYGON_RPC_URL,
  REACT_APP_DOGECHAIN_RPC_URL,
  REACT_APP_ETH_RPC_URL,
  REACT_APP_BNB_RPC_URL
}

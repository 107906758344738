import React from 'react'
import { Col, Row } from 'react-bootstrap'

import { TeamCard } from './TeamCard'
import { TeamMember } from './types'

import './TeamBlock.css'

interface ITeamSectionProps {
  teamMembers: TeamMember[]
}

export const TeamBlock = ({ teamMembers }: ITeamSectionProps) => {
  return (
    <div className={'team'}>
      <Row className="team__list g-5 justify-content-start">
        {teamMembers.map((member, index) => (
          <Col
            key={member.name + index}
            xs={{ span: 6 }}
            md={{ span: 4 }}
            lg={{ span: 3 }}
          >
            <TeamCard
              avatar={`team/${member.avatar}`}
              name={member.name}
              bio={member.bio}
            />
          </Col>
        ))}
      </Row>
    </div>
  )
}

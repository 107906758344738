import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { INFTToken } from '@store/types'

import { NftCard } from '../NFTCard'

interface INFTListProps {
  NFTArray: INFTToken[]
}

export const NftList = ({ NFTArray }: INFTListProps) => {
  return (
    <Row className="mt-3 mb-5 g-3 justify-content-center justify-content-md-start">
      {NFTArray.map(({ phase, id, image_url, ...token }) => (
        <Col
          key={id}
          md={{ span: 6 }}
          lg={{ span: 4 }}
          className={'justify-content-center'}
        >
          <NftCard
            {...token}
            phase={phase}
            asset_id={id}
            id={id}
            collection_id={token.collection?.id!}
            image={image_url}
            collection_name={token.collection?.name!}
            fundToken={token.collection?.presale?.fund_token!}
          />
        </Col>
      ))}
    </Row>
  )
}

import React from 'react'

import {
  AboutSection,
  LaunchProjectSection,
  TopBanner,
  UpcomingDropsSection
} from '@components/index'

import './Landing.css'

export const Landing = () => {
  return (
    <main className={'page'}>
      <TopBanner />

      <AboutSection />

      <UpcomingDropsSection />

      <LaunchProjectSection />
    </main>
  )
}

export default Landing

import React from 'react'
import { Container } from 'react-bootstrap'
import classNames from 'classnames'

import { NormalizedCollectionInfo } from '@/components'

import { ArtCard, INFTCardData } from '@components/Collections/ArtCard'

import NFTCardImage from '@assets/NFT/nft-card.gif'
import NFTCardImage1 from '@assets/upcoming-drops/nft-1.png'
import NFTCardImage2 from '@assets/upcoming-drops/nft-2.png'
import NFTCardImage3 from '@assets/upcoming-drops/nft-3.png'

import './ArtList.scss'

interface IArtListProps {
  collections: NormalizedCollectionInfo[]
}

const KNOWN_GALLERY_COLLECTION_IDS = [
  'QYJHq6bSdQs2IDULMbKx',
  'Pd1JmstpL9GKLfmvPQZq',
  'HSF89ECXPbdKEuVC7Ug0'
]

const NFTS_DICT: Record<string, INFTCardData[]> = {
  QYJHq6bSdQs2IDULMbKx: [
    {
      image: NFTCardImage1,
      id: 1,
      cost: 1.5,
      name: 'Nft name'
    },
    {
      image: NFTCardImage1,
      id: 2,
      cost: 1.5,
      name: 'Nft name'
    }
  ],
  Pd1JmstpL9GKLfmvPQZq: [
    {
      image: NFTCardImage2,
      id: 1,
      cost: 1.5,
      name: 'Nft name'
    },
    {
      image: NFTCardImage2,
      id: 2,
      cost: 1.5,
      name: 'Nft name'
    }
  ],
  HSF89ECXPbdKEuVC7Ug0: [
    {
      image: NFTCardImage3,
      id: 1,
      cost: 1.5,
      name: 'Nft name'
    },
    {
      image: NFTCardImage3,
      id: 2,
      cost: 1.5,
      name: 'Nft name'
    }
  ]
}

const DEFAULT_NFT_LIST = [
  {
    image: NFTCardImage3,
    id: 1,
    cost: 1.5,
    name: 'Nft name'
  },
  {
    image: NFTCardImage3,
    id: 2,
    cost: 1.5,
    name: 'Nft name'
  }
]

export const ArtList = ({ collections }: IArtListProps) => {
  const arts = collections.sort((a, b) => (a.name < b.name ? -1 : 1))
  return (
    <>
      {arts?.map(({ name, id, assets, max_nft_amount, info }, index) => (
        <div
          className={classNames([
            'art-wrapper',
            { 'art-wrapper--grey': index % 2 !== 0 }
          ])}
          key={id}
        >
          <Container>
            <ArtCard
              id={id}
              name={name}
              assets={assets}
              description={info.description}
              nfts_list={NFTS_DICT[id] || DEFAULT_NFT_LIST}
            />
          </Container>
        </div>
      ))}
    </>
  )
}

import React, { useState } from 'react'
import {
  Accordion as BaseAccordion,
  Button,
  Card,
  useAccordionToggle
} from 'react-bootstrap'

import { ReactComponent as CollapseArrow } from '@assets/accordion/collapse.svg'
import { ReactComponent as CollapsedArrow } from '@assets/accordion/collapsed.svg'
import { ReactComponent as HeaderArrow } from '@assets/header-accordion-arrow.svg'

import './Accordion.css'
interface Item {
  title: string
  body: React.ReactNode
}

interface Props {
  items: Item[]
  type?: 'header' | 'common'
}

const CustomToggle = ({ children, eventKey, type }: any) => {
  const [isOpened, setOpened] = useState(false)
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setOpened(!isOpened)
  })

  const isHeader = type === 'header'

  return (
    <Button
      variant="main"
      className={isOpened ? 'active' : ''}
      onClick={decoratedOnClick}
    >
      <>
        {children}
        <span>
          {!isHeader ? (
            isOpened ? (
              <CollapsedArrow />
            ) : (
              <CollapseArrow />
            )
          ) : (
            <span
              style={{ transform: `rotate(${!isOpened ? '180deg' : '0'})` }}
            >
              <HeaderArrow />
            </span>
          )}
        </span>
      </>
    </Button>
  )
}

export const Accordion = ({ items, type = 'common' }: Props) => {
  const itemList = items.map((item, i) => (
    <BaseAccordion key={item.title}>
      <Card className="accordion">
        <Card.Header>
          <CustomToggle eventKey={`${i}`} type={type}>
            {item.title}
          </CustomToggle>
        </Card.Header>
        <BaseAccordion.Collapse eventKey={`${i}`}>
          <Card.Body>{item.body}</Card.Body>
        </BaseAccordion.Collapse>
      </Card>
    </BaseAccordion>
  ))

  return <>{itemList}</>
}

import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useIsMounted } from '@firestarter-private/firestarter-library/lib/hooks/helpers/useIsMounted'
import { ActionType, INFTToken, NFTMetadata } from '@store/types'
import { useWeb3React } from '@web3-react/core'

import { _getNftsData } from '@api/nftMetadata/getNftsImages'
import { getUserNfts } from '@api/subgraph/nfts/queries'
import { ISubgraphGetTokensResponse } from '@api/subgraph/nfts/types'

import { useNFTPagination } from '@contracts/hooks/NFT/useNFTPagination'
import { getTokensData } from '@contracts/utils/getTokensData'

import { useDispatch } from '@hooks/useDispatch'

import { sendExceptionReport } from '@utils/errors'

import { ContractAddress, WalletAddress } from '../../address'

export const useGetUserTokens = (
  collectionAddress: ContractAddress,
  pageSize: number = 9
) => {
  const dispatch = useDispatch()
  const { account } = useWeb3React()
  const [loadingTokensData, setLoadingTokensData] = useState<boolean>(false)
  const { totalNFTAmount, setTotalNFTAmount, page, setPage } =
    useNFTPagination()
  const isMountedRef = useIsMounted()

  const { loading, refetch } = useQuery<ISubgraphGetTokensResponse>(
    getUserNfts,
    {
      variables: {
        first: pageSize,
        skip: (page - 1) * pageSize,
        owner: account?.toLowerCase()
      },
      onCompleted: async (data) => {
        setLoadingTokensData(true)
        const nftTokens = await getTokensData(data.tokens)
        isMountedRef.current &&
          data?.tokens &&
          dispatch({
            type:
              page === 1 ? ActionType.SET_USER_NFTS : ActionType.ADD_USER_NFTS,
            payload: nftTokens
          })

        setLoadingTokensData(false)
      }
    }
  )

  const { loading: loadingTotal, refetch: refetchTotal } =
    useQuery<ISubgraphGetTokensResponse>(getUserNfts, {
      variables: {
        first: 1000,
        skip: 0,
        owner: account?.toLowerCase()
      },
      onCompleted: async (data) => {
        isMountedRef.current && setTotalNFTAmount(data.tokens.length)
      }
    })

  const getNFTAmount = useCallback(async () => {
    if (!account) return
    await refetchTotal()
  }, [account])

  useEffect(() => {
    getNFTAmount()
  }, [account])

  useEffect(() => {
    return () =>
      dispatch({
        type: ActionType.SET_USER_NFTS,
        payload: null
      })
  }, [])

  const getUserTokens = useCallback(
    async (account?: WalletAddress | null) => {
      if (!totalNFTAmount) return
      dispatch({ type: ActionType.SET_FETCHING_NFT_COLLECTION, payload: true })

      if (!account) {
        dispatch({
          type: ActionType.SET_USER_NFTS,
          payload: []
        })
        dispatch({
          type: ActionType.SET_FETCHING_NFT_COLLECTION,
          payload: false
        })
        return
      }

      try {
        await refetch()

        setPage((prevPage) => prevPage + 1)
      } catch (err) {
        sendExceptionReport(err)
        dispatch({
          type: ActionType.SET_USER_NFTS,
          payload: []
        })
      } finally {
        dispatch({
          type: ActionType.SET_FETCHING_NFT_COLLECTION,
          payload: false
        })
      }
    },
    [account, collectionAddress, page, totalNFTAmount]
  )

  return {
    getUserTokens,
    totalUserNFTAmount: totalNFTAmount,
    loading: loadingTotal || loading || loadingTokensData
  }
}

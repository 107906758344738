import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from 'react-bootstrap'

import { DynamicImage } from '@/components'

import { LaunchForm } from '@components/layout/LaunchModal/LaunchForm'

import './LaunchModal.scss'

interface ILaunchModalProps {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}

export const LaunchModal = ({
  setShow,
  show
}: ILaunchModalProps): JSX.Element => {
  return (
    <Modal
      show={show}
      centered
      onHide={() => setShow(false)}
      className="launch-modal"
      contentClassName="tile text-center"
    >
      <button className="close-button" onClick={() => setShow(false)}>
        <DynamicImage path={'close.svg'} />
      </button>
      <Modal.Body className="p-0">
        <LaunchForm setLaunchModalShow={setShow} />
      </Modal.Body>
    </Modal>
  )
}

import React from 'react'
import { Container } from 'react-bootstrap'

import { ROADMAP_DATA } from '@/mocks/roadmap'

import { TimeLine } from '@components/common'

import './RoadmapSection.css'

export const RoadmapSection = () => {
  return (
    <section className={'roadmap-section'}>
      <Container>
        <h2 className="title mb-5">The Roadmap</h2>
        <TimeLine timelineData={ROADMAP_DATA} />
      </Container>
    </section>
  )
}

import React, { useEffect, useMemo, useState } from 'react'

import {
  EmptyNftListTile,
  LoadingWrap,
  NftList,
  RoundButton,
  SiteButton
} from '@components/index'

import { useNFT } from '@contracts/hooks/NFT/useNFT'

import { NormalizedCollectionInfo } from '../../CollectionPresale'

interface INFTListBlockProps {
  collection: NormalizedCollectionInfo
}

export const NFTListBlock = ({ collection }: INFTListBlockProps) => {
  const { NFTTokens, getNFTTokens, loadingTokens, totalNFTAmount } = useNFT(
    collection?.presale?.reward_token?.address || ''
  )
  const isFirstLoad = useMemo(() => !NFTTokens?.length, [NFTTokens])

  return (
    <LoadingWrap loading={loadingTokens && isFirstLoad}>
      {collection && NFTTokens && NFTTokens?.length ? (
        <>
          <NftList NFTArray={NFTTokens} />

          <LoadingWrap loading={loadingTokens && !isFirstLoad}>
            {totalNFTAmount > NFTTokens.length && (
              <div className={'d-flex mt-4 justify-content-center'}>
                <SiteButton
                  size={'large'}
                  bordered
                  color={'DARK'}
                  onClick={getNFTTokens}
                >
                  Load more
                </SiteButton>
              </div>
            )}
          </LoadingWrap>
        </>
      ) : (
        <EmptyNftListTile />
      )}
    </LoadingWrap>
  )
}

import axios, { CancelToken } from 'axios'

import { INFTMetadata } from '@api/nftMetadata/types'

import { sendExceptionReport } from '@utils/errors'

import { instance as API } from '../config'

export const _getNftsData = async (
  tokens: Array<Record<string, string>>,
  cancelToken?: CancelToken
): Promise<INFTMetadata[]> => {
  try {
    const { data } = await API.post<INFTMetadata[]>(
      '/get-nfts-data',
      {
        tokens
      },
      {
        cancelToken
      }
    )

    return data
  } catch (err) {
    if (err instanceof axios.Cancel) {
      throw err
    }
    sendExceptionReport(err)
    return []
  }
}

import React from 'react'
import { Image } from 'react-bootstrap'
import { generatePath, Link } from 'react-router-dom'
import { useDecimals } from '@firestarter-private/firestarter-library'
import { RoutesPaths } from '@router/constants'
import { INFTToken } from '@store/types'
import BigNumber from 'bignumber.js'

import { TokenInfo } from '@/components'

import { balanceToCurrency } from '@utils/currency'

import NFTPreview from '@assets/NFT/NFT-placeholder.svg'

import './NFTCard.scss'

interface INFTCardProps extends INFTToken {
  collection_id: string
  asset_id: string
  collection_name: string
  fundToken: TokenInfo
}

export const NftCard = ({
  asset_id,
  collection_id,
  tokenId,
  image,
  collection_name,
  phase,
  fundToken
}: INFTCardProps) => {
  const decimals = useDecimals(fundToken?.address)

  return (
    <Link
      className="nft-card tile"
      to={generatePath(RoutesPaths.NFT_ASSET, {
        collection_id,
        asset_id
      })}
    >
      <div className={'nft-card__img'}>
        <Image src={image || NFTPreview} />
      </div>

      <div className="nft-card__body">
        <div
          className={'d-flex justify-content-between align-items-center mt-3'}
        >
          <div className="nft-card__title">
            {'NFT'} #{tokenId}
          </div>

          <div className={'nft-card__cost'}>
            {balanceToCurrency(new BigNumber(phase.price), decimals)}{' '}
            {fundToken.name}
          </div>
        </div>

        <div className="nft-card__name">{collection_name}</div>
      </div>
    </Link>
  )
}

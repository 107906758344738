export interface ISocialLink {
  name: string
  url: string
}

export const SOCIAL_LINKS: ISocialLink[] = [
  {
    name: 'twitter',
    url: 'https://twitter.com/whitelabalproject'
  },
  {
    name: 'telegram',
    url: 'https://t.me/whitelabalproject'
  }
]

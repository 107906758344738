import React, { Dispatch, SetStateAction } from 'react'
import { Image, Modal, Tooltip } from 'react-bootstrap'
import { generatePath } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'

import { CommonTooltip, DynamicImage, SiteButton } from '@/components'

import NFTCard from '@assets/NFT/NFT-placeholder.png'

import './SuccessBoughtModal.scss'

interface ISuccessBoughtModalProps {
  show: boolean
  setShow: Dispatch<SetStateAction<boolean>>
}

export const SuccessBoughtModal = ({
  show,
  setShow
}: ISuccessBoughtModalProps): JSX.Element => {
  return (
    <div className="success-bought-modal">
      <Modal
        show={show}
        centered
        onHide={() => setShow(false)}
        className="success-bought-modal"
        contentClassName="tile text-center"
      >
        <button className="close-button" onClick={() => setShow(false)}>
          <DynamicImage path={'close.svg'} />
        </button>

        <div className="success-bought-modal__dog">
          <DynamicImage path={'success-bought.svg'} />
        </div>
        <Modal.Body className="p-0">
          <div className="success-bought-modal__image">
            <Image src={NFTCard} />
          </div>

          <div className="success-bought-modal__title">Woohoo!!</div>

          <div className="success-bought-modal__description">
            Your NFT will be revealed at a later time
          </div>

          <div className="success-bought-modal__button">
            <SiteButton
              color={'YELLOW'}
              size={'large'}
              to={generatePath(RoutesPaths.ACCOUNT.MY_COLLECTIONS)}
            >
              View NFT
            </SiteButton>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

import { useCallback, useMemo } from 'react'
import { useNotify } from '@firestarter-private/firestarter-library'
import { useGasPrice } from '@firestarter-private/firestarter-library'
import { NetworksIDs } from '@firestarter-private/firestarter-library/lib/constants/networks'
import {
  INotifyTxCallbacks,
  INotifyWrapperResult
} from '@firestarter-private/firestarter-library/lib/hooks/useNotify'
import { useWeb3React } from '@web3-react/core'
import { BlockNumber, TransactionConfig } from 'web3-core'

import { useWeb3 } from '@hooks/useWeb3'

export interface IUseTransactions {
  callTransaction: <T = any>(
    txSignature: any,
    blockNumber?: BlockNumber
  ) => Promise<T>
  sendTransaction: (
    txSignature: any,
    callbacks?: INotifyTxCallbacks
  ) => Promise<INotifyWrapperResult>
}

export function useTransactions(): IUseTransactions {
  const { notifyWrapper } = useNotify()
  const { account, chainId } = useWeb3React()
  const { gasPrice, currentGasMultiplier } = useGasPrice()
  const currentNetworkId = chainId && chainId.toString()
  const web3 = useWeb3()

  const txConfig = useMemo<TransactionConfig>(
    () => ({
      from: account || undefined,
      gasPrice:
        // TODO: use gas station for dogechain when we will have it, for now we use const 50GWei
        [NetworksIDs.dogechain, NetworksIDs.dogechaindev].includes(
          currentNetworkId as NetworksIDs
        )
          ? web3.utils.toWei(String(50), 'gwei')
          : web3.utils.toWei(String(gasPrice), 'gwei')
    }),
    [gasPrice, account]
  )

  const callTransaction = useCallback(
    async (txSignature: any, blockNumber: BlockNumber = 'latest') => {
      if (!currentNetworkId) return

      const nonce = await web3.eth.getTransactionCount(account || '')

      return await txSignature.call({ from: account || undefined }, blockNumber)
    },
    [account, currentNetworkId]
  )

  const sendTransaction = useCallback(
    async (
      txSignature: any,
      callbacks: INotifyTxCallbacks = {}
    ): Promise<INotifyWrapperResult> => {
      if (!currentNetworkId) return { error: 'No network selected' }

      let gasLimit: number

      try {
        gasLimit = await txSignature.estimateGas(txConfig)
      } catch (err) {
        throw err
      }

      const finalGasLimit = Math.floor(gasLimit * currentGasMultiplier)

      const txCall = (txDetails: TransactionConfig) =>
        txSignature.send(txDetails)
      return await notifyWrapper(
        txCall({
          ...txConfig,
          gas: finalGasLimit
        }),
        callbacks
      )
    },
    [currentNetworkId, txConfig, notifyWrapper]
  )

  return {
    callTransaction,
    sendTransaction
  }
}

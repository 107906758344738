import React from 'react'
import { Form } from 'react-bootstrap'
import { useFormikContext } from 'formik'

import { SiteButton, Spinner } from '@/components'

import {
  LaunchFormValues,
  ProjectTypesEnum
} from '@components/layout/LaunchModal/LaunchForm'

import { ReactComponent as InfoIcon } from '@assets/info-icon.svg'

import './StepProject.scss'

interface IStepProjectProps {
  loading: boolean
  failed: boolean
}

export const StepProject = ({
  loading,
  failed
}: IStepProjectProps): JSX.Element => {
  const { getFieldProps, isValid, touched, errors, values } =
    useFormikContext<LaunchFormValues>()

  return (
    <div className="step-project">
      <h2 className="title">Project Details</h2>
      <Form.Group controlId="project_type">
        <Form.Label>I want to launch</Form.Label>

        <div className={'project-type-wrapper'}>
          <Form.Check
            inline
            type={'radio'}
            {...getFieldProps('project_type')}
            id={ProjectTypesEnum.NFT_LAUNCH}
            checked={values.project_type === ProjectTypesEnum.NFT_LAUNCH}
            value={ProjectTypesEnum.NFT_LAUNCH}
            label={ProjectTypesEnum.NFT_LAUNCH}
          />

          <Form.Check
            inline
            type={'radio'}
            {...getFieldProps('project_type')}
            label={ProjectTypesEnum.TOKEN_LAUNCH}
            value={ProjectTypesEnum.TOKEN_LAUNCH}
            checked={values.project_type === ProjectTypesEnum.TOKEN_LAUNCH}
            id={ProjectTypesEnum.TOKEN_LAUNCH}
          />

          <Form.Check
            inline
            type={'radio'}
            {...getFieldProps('project_type')}
            label={ProjectTypesEnum.DEX_LISTING}
            value={ProjectTypesEnum.DEX_LISTING}
            checked={values.project_type === ProjectTypesEnum.DEX_LISTING}
            id={ProjectTypesEnum.DEX_LISTING}
          />
        </div>
      </Form.Group>

      <Form.Group controlId="project_name">
        <Form.Label>Project Name*</Form.Label>
        <Form.Control
          type="text"
          placeholder="Project Name"
          {...getFieldProps('project_name')}
          isValid={touched.project_name && !errors.project_name}
          isInvalid={touched.project_name && !!errors.project_name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.project_name}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="project_website">
        <Form.Label>Project’s Website*</Form.Label>
        <Form.Control
          type="text"
          placeholder="www."
          {...getFieldProps('project_website')}
          isValid={touched.project_website && !errors.project_website}
          isInvalid={touched.project_website && !!errors.project_website}
        />
        <Form.Control.Feedback type="invalid">
          {errors.project_website}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="project_telegram">
        <Form.Label>Project’s Telegram*</Form.Label>
        <Form.Control
          type="text"
          placeholder="@"
          {...getFieldProps('project_telegram')}
          isValid={touched.project_telegram && !errors.project_telegram}
          isInvalid={touched.project_telegram && !!errors.project_telegram}
        />
        <Form.Control.Feedback type="invalid">
          {errors.project_telegram}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group controlId="project_twitter">
        <Form.Label>Project’s Twitter</Form.Label>
        <Form.Control
          type="text"
          placeholder="@"
          {...getFieldProps('project_twitter')}
          isValid={touched.project_twitter && !errors.project_twitter}
          isInvalid={touched.project_twitter && !!errors.project_twitter}
        />
        <Form.Control.Feedback type="invalid">
          {errors.project_twitter}
        </Form.Control.Feedback>
      </Form.Group>

      <SiteButton
        type="submit"
        size="large"
        color={'YELLOW'}
        disabled={!isValid || loading}
      >
        {loading ? <Spinner /> : <span>Submit</span>}
      </SiteButton>
      {failed && (
        <div className="form-message form-message--warning text-center mt-3">
          <InfoIcon />
          <span>Oops! Something goes wrong! Check your data and try again</span>
        </div>
      )}
    </div>
  )
}

import React, { useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { generatePath, Link } from 'react-router-dom'

import { LoadingWrap, SiteButton } from '@/components'
import { RoutesPaths } from '@/router/constants'

import { useCollectionsState } from '@hooks/useCollections'

import './TopBanner.scss'

const COLLECTION_ID = process.env.REACT_APP_NFT_COLLECTION_ID || ''

export const TopBanner = () => {
  const {
    currentCollection: collection,
    getCollectionById,
    loading
  } = useCollectionsState()

  useEffect(() => {
    if (collection?.id !== COLLECTION_ID || !collection) {
      getCollectionById(COLLECTION_ID)
    }
  }, [collection, COLLECTION_ID])

  return (
    <section className={'top-banner'}>
      <Container>
        <Row className={'align-items-center'}>
          <Col md={6}>
            <div className="top-banner__left">
              <h1 className="top-banner__title">PRO THE DOGE</h1>
              <div className="top-banner__description">
                Own NFT's from the worlds best HODLR
              </div>
              <SiteButton
                size={'large'}
                color={'YELLOW'}
                to={generatePath(RoutesPaths.COLLECTION_DETAILS, {
                  collection_id: COLLECTION_ID
                })}
              >
                Open collection
              </SiteButton>
            </div>
          </Col>

          <Col md={6}>
            <LoadingWrap loading={loading}>
              <div className="collection-banner-card">
                <Link
                  to={generatePath(RoutesPaths.COLLECTION_DETAILS, {
                    collection_id: COLLECTION_ID
                  })}
                >
                  <div className="collection-banner-card__badge">
                    Featured Art
                  </div>
                  <div className="collection-banner-card__image">
                    <Image src={collection?.assets.nft_image_url} alt={'NFT'} />
                  </div>
                  <footer className="collection-banner-card__metadata collection-metadata">
                    <div className={'collection-metadata__item'}>
                      <div className="collection-metadata__attribute">
                        Artist
                      </div>
                      <div className="collection-metadata__value">
                        <div className="collection-metadata__ico">
                          <Image src={collection?.artist.image} />
                        </div>
                        {collection?.artist.name}
                      </div>
                    </div>
                    <div className={'collection-metadata__item'}>
                      <div className="collection-metadata__attribute">Name</div>
                      <div className="collection-metadata__value">
                        {collection?.name}
                      </div>
                    </div>
                    <div className={'collection-metadata__item'}>
                      <div className="collection-metadata__attribute">
                        Release Date
                      </div>
                      <div className="collection-metadata__value">TBA</div>
                    </div>
                  </footer>
                </Link>
              </div>
            </LoadingWrap>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export const RoutesPaths = {
  MAIN: '/',
  ART: '/art/:collections_status?',
  COLLECTION_DETAILS: '/collections/:collection_id',
  ACCOUNT: {
    MY_COLLECTIONS: '/account/my-collections'
  },
  ABOUT_US: '/about-us',
  ASSET_AUCTION: '/assets/:collection_id/:asset_id/auction',
  NFT_ASSET: '/assets/:collection_id/:asset_id',
  WHITELIST: '/whitelist/:collection_id',
  ROADMAP: '/roadmap',
  MARKET: '/market',
  CALENDAR: '/calendar',
  FAQ: '/faq'
}

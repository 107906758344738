import { useCallback, useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { BlockNumber } from 'web3-core'
import { Contract } from 'web3-eth-contract'

import { ContractAddress } from '@/components'

import { useIsMounted } from '@hooks/useIsMounted'

import { useTokenContract } from './useTokenContract'
import { useTransactions } from './useTransactions'

export function useTokenBalance(
  tokenAddress?: ContractAddress,
  loading = false,
  blockNumber: BlockNumber = 'latest'
): BigNumber {
  const isMountedRef = useIsMounted()
  const [balance, setBalance] = useState<BigNumber>(new BigNumber(0))
  const { account } = useWeb3React()
  const tokenContract = useTokenContract(tokenAddress)
  const { callTransaction } = useTransactions()

  const fetchBalance = useCallback(async () => {
    if (!tokenContract || !account) {
      setBalance(new BigNumber(0))
      return
    }

    const res = await callTransaction(
      (tokenContract as unknown as Contract).methods.balanceOf(account),
      // @ts-ignore
      blockNumber
    )
    isMountedRef.current && setBalance(new BigNumber(res))
  }, [tokenContract, account, isMountedRef, blockNumber])

  useEffect(() => {
    if (!loading && account && tokenContract) {
      fetchBalance()
    }
  }, [loading, blockNumber, account, tokenContract])

  return balance
}

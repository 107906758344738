import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { generatePath, Link } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'

import { GasTracker, SocialLinks } from '@/components'
import { SOCIAL_LINKS } from '@/constants'

import logo from '@assets/logo-dogechain.png'

import './Footer.scss'

export const Footer = () => {
  return (
    <footer className={'footer'}>
      <Container>
        <Row>
          <Col md={8}>
            <Row>
              <Col md={6} className={'footer__left-column'}>
                <Link to={RoutesPaths.MAIN} className={'footer__logo'}>
                  <img
                    src={logo}
                    alt="Logo"
                    className="d-inline-block align-top"
                  />
                </Link>
                <GasTracker />
              </Col>

              <Col md={3}>
                <h4 className="footer-info-block__title">Art</h4>
                <ul className={'footer-menu'}>
                  <li className={'footer-menu__item'}>
                    <Link to={generatePath(RoutesPaths.ART)}>
                      NFT Collections
                    </Link>
                  </li>
                  <li className={'footer-menu__item'}>
                    <Link
                      to={generatePath(RoutesPaths.ART, {
                        collections_status: 'upcoming'
                      })}
                    >
                      Upcoming Drops
                    </Link>
                  </li>
                </ul>
              </Col>

              <Col md={3}>
                <h4 className="footer-info-block__title">Developers</h4>
                <ul className={'footer-menu'}>
                  <li className={'footer-menu__item'}>
                    <Link to={generatePath(RoutesPaths.CALENDAR)}>
                      Calendar
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>

          <Col md={4}>
            <Row>
              <Col md={6}>
                <div className="footer-info-block">
                  <h4 className="footer-info-block__title">Help</h4>
                  <ul className={'footer-menu'}>
                    <li className={'footer-menu__item'}>
                      <a href={'mailto:support@microwave.dog'}>Support</a>
                    </li>
                    <li className={'footer-menu__item'}>
                      <Link to={generatePath(RoutesPaths.FAQ)}>FAQ</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6}>
                <div className="footer-info-block">
                  <h4 className="footer-info-block__title">Social</h4>
                  <SocialLinks socialLinks={SOCIAL_LINKS} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="footer__copyright">© 2022 Microwave</div>
      </Container>
    </footer>
  )
}

import React from 'react'
import { Container } from 'react-bootstrap'

import { DropsSlider, LoadingWrap } from '@/components'

import { useCalendarData } from '@hooks/useCalendarData'

import './UpcomingDropsSection.scss'

export const UpcomingDropsSection = () => {
  const { phases, loading } = useCalendarData()

  return (
    <section className={'upcoming-drops-section'}>
      <Container>
        <h2 className="title mb-5">Upcoming NFT Drops</h2>
      </Container>
      <LoadingWrap loading={loading}>
        <DropsSlider phases={phases} />
      </LoadingWrap>
    </section>
  )
}

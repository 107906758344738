import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useWeb3React } from '@web3-react/core'

import { SiteButton } from '@/components'

import { WalletAddress } from '@contracts/address'

import { useWalletConnect } from '@hooks/useWalletConnect'

import { shorterETHAddress } from '@utils/string'

import './ConnectWalletTile.scss'

export const ConnectWalletTile = () => {
  const { account, active } = useWeb3React()

  const { onClickWallet } = useWalletConnect()

  return (
    <Row className="connect-row">
      <Col md={{ span: 5 }} className="text-center column tile">
        <h4 className="tile__main">Connect your wallet</h4>
        <div>
          <SiteButton color="YELLOW" size="large" onClick={onClickWallet}>
            <>
              {active
                ? shorterETHAddress(account as WalletAddress)
                : 'Connect Wallet'}
            </>
          </SiteButton>
        </div>
      </Col>
    </Row>
  )
}

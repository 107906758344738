import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useNetwork } from '@firestarter-private/firestarter-library'
import { ActionType, INFTToken } from '@store/types'
import { useWeb3React } from '@web3-react/core'

import { _getNftsData } from '@/api'
import { sendExceptionReport } from '@/utils/errors'

import {
  getCollectionNfts,
  getCollectionPhases,
  getUserNfts
} from '@api/subgraph/nfts/queries'
import {
  ISubgraphGetPhasesResponse,
  ISubgraphGetTokensResponse
} from '@api/subgraph/nfts/types'

import { useNFTPagination } from '@contracts/hooks/NFT/useNFTPagination'
import { getTokensData } from '@contracts/utils/getTokensData'

import { useDispatch } from '@hooks/useDispatch'
import { useIsMounted } from '@hooks/useIsMounted'

import { ContractAddress } from '../../address'
import { useNftCollection } from '../useNftCollection'

export const useGetNFTTokens = (
  collectionAddress: ContractAddress,
  pageSize: number = 9
) => {
  const dispatch = useDispatch()
  const { totalNFTAmount, setTotalNFTAmount, page, setPage } =
    useNFTPagination()
  const [phasesIds, setPhasesIds] = useState<Array<string>>([])
  const { currentNetworkId } = useNetwork()
  const { account } = useWeb3React()
  const [loadingTokensData, setLoadingTokensData] = useState<boolean>(false)
  const isMountedRef = useIsMounted()

  const { loading, refetch } = useQuery<ISubgraphGetPhasesResponse>(
    getCollectionPhases,
    {
      variables: {
        first: 100,
        skip: 0,
        collection: collectionAddress.toLowerCase()
      },
      onCompleted: async (data) => {
        isMountedRef.current &&
          setPhasesIds(data.phases.map((phase) => phase.id))
      }
    }
  )

  const { loading: loadingTokens, refetch: refetchTokens } =
    useQuery<ISubgraphGetTokensResponse>(getCollectionNfts, {
      variables: {
        first: pageSize,
        skip: (page - 1) * pageSize,
        phases: phasesIds
      },
      onCompleted: async (data) => {
        setLoadingTokensData(true)
        const nftTokens = await getTokensData(data.tokens)

        isMountedRef.current &&
          dispatch({
            type:
              page === 1
                ? ActionType.SET_NFT_TOKENS
                : ActionType.ADD_NFT_TOKENS,
            payload: nftTokens
          })
        setLoadingTokensData(false)
      }
    })

  const { loading: loadingTotal, refetch: refetchTotal } =
    useQuery<ISubgraphGetPhasesResponse>(getCollectionPhases, {
      variables: {
        first: 1000,
        skip: 0,
        collection: collectionAddress.toLowerCase()
      },
      onCompleted: async (data) => {
        const tokens = data.phases.reduce((prev: any[], curr) => {
          return [...prev, ...(curr?.tokens?.length ? curr.tokens : [])]
        }, [])
        isMountedRef.current && setTotalNFTAmount(tokens.length)
      }
    })

  const getNFTAmount = useCallback(async () => {
    refetchTotal()
  }, [collectionAddress])

  useEffect(() => {
    if (collectionAddress) {
      getNFTAmount()
    }
  }, [collectionAddress])

  useEffect(() => {
    refetchTokens()
  }, [phasesIds])

  useEffect(() => {
    refetch()
  }, [collectionAddress])

  useEffect(() => {
    return () =>
      dispatch({
        type: ActionType.SET_NFT_TOKENS,
        payload: null
      })
  }, [])

  const getNFTTokens = useCallback(async () => {
    if (!totalNFTAmount) return
    dispatch({ type: ActionType.SET_FETCHING_NFT_COLLECTION, payload: true })

    try {
      await refetch()

      setPage((prevPage) => prevPage + 1)
    } catch (err) {
      sendExceptionReport(err)
      dispatch({
        type: ActionType.SET_NFT_TOKENS,
        payload: []
      })
    } finally {
      dispatch({
        type: ActionType.SET_FETCHING_NFT_COLLECTION,
        payload: false
      })
    }
  }, [account, collectionAddress, currentNetworkId, totalNFTAmount, page])

  return {
    getNFTTokens,
    totalNFTAmount,
    loading: loading || loadingTokens || loadingTotal || loadingTokensData
  }
}

import React, { useCallback, useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useGasPrice } from '@firestarter-private/firestarter-library'
import { useWeb3React } from '@web3-react/core'

import { Calendar } from '@/pages'

import {
  Footer,
  Header,
  ScrollRestoration,
  ScrollToTop,
  Spinner,
  WrongNetworkModal
} from '@components/index'
import { MobileConnectWalletButton } from '@components/layout/MobileConnectWalletButton/MobileConnectWalletButton'

import { useMediaDimensions } from '@hooks/useMediaDimensions'

import { sendExceptionReport } from '@utils/errors'

import { RoutesPaths } from './constants'

const AboutUs = React.lazy(() => import('@pages/AboutUs/AboutUs'))
const CollectionDetails = React.lazy(
  () => import('@pages/CollectionDetails/CollectionDetails')
)
const Collections = React.lazy(() => import('@pages/Art/Art'))
const Landing = React.lazy(() => import('@pages/Landing/Landing'))
const Market = React.lazy(() => import('@pages/Market/Market'))
const MyCollection = React.lazy(
  () => import('@pages/MyCollection/MyCollection')
)
const NFTAuctionPage = React.lazy(
  () => import('@pages/NFTAuctionPage/NFTAuctionPage')
)
const NFTPage = React.lazy(() => import('@pages/NFTPage/NFTPage'))
const Roadmap = React.lazy(() => import('@pages/Roadmap/Roadmap'))
const Wallet = React.lazy(() => import('@pages/Wallet/Wallet'))
const Whitelist = React.lazy(() => import('@pages/Whitelist/Whitelist'))
const FAQ = React.lazy(() => import('@pages/FAQ/FAQ'))

const Routes = () => {
  const { error, account } = useWeb3React()
  const { sm } = useMediaDimensions()

  return (
    <BrowserRouter>
      <ScrollRestoration>
        <Header />
        <React.Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path={RoutesPaths.MAIN} component={Landing} />
            <Route
              exact
              path={RoutesPaths.ACCOUNT.MY_COLLECTIONS}
              component={account ? MyCollection : Wallet}
            />
            <Route exact path={RoutesPaths.ABOUT_US} component={AboutUs} />
            <Route exact path={RoutesPaths.ART} component={Collections} />
            <Route
              exact
              path={RoutesPaths.COLLECTION_DETAILS}
              component={CollectionDetails}
            />
            <Route exact path={RoutesPaths.NFT_ASSET} component={NFTPage} />
            <Route
              exact
              path={RoutesPaths.ASSET_AUCTION}
              component={NFTAuctionPage}
            />
            <Route exact path={RoutesPaths.WHITELIST} component={Whitelist} />
            <Route exact path={RoutesPaths.ROADMAP} component={Roadmap} />
            <Route exact path={RoutesPaths.MARKET} component={Market} />
            <Route exact path={RoutesPaths.CALENDAR} component={Calendar} />
            <Route exact path={RoutesPaths.FAQ} component={FAQ} />
            <Route path="/*" component={Landing} />
          </Switch>
        </React.Suspense>
        <ScrollToTop />
        <WrongNetworkModal error={error} />
        <Footer />
        {!sm && <MobileConnectWalletButton />}
      </ScrollRestoration>
    </BrowserRouter>
  )
}
export default Routes

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { AsideSection } from '../AsideSection'
import { IAsideItem, IMarketItem } from '../types'

interface IPriceSortProps {
  setSort: Dispatch<SetStateAction<any>>
}

export const PriceSort = ({ setSort }: IPriceSortProps) => {
  const [items, setItems] = useState<IAsideItem[]>([])

  useEffect(() => {
    setItems([
      {
        text: 'Low to high',
        onClick: () => {
          setSort('asc')
        },
        selected: true
      },
      {
        text: 'High to Low',
        onClick: () => {
          setSort('desc')
        },
        selected: false
      }
    ])
  }, [])

  return <AsideSection title={'Price'} items={items} setItems={setItems} />
}

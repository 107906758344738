import React, { useMemo } from 'react'
import { Container } from 'react-bootstrap'
import { useWeb3React } from '@web3-react/core'

import {
  MyCollectionBanner,
  MyCollectionNftListBlock,
  WrongNetworkBlock
} from '@components/index'

import NFTImage from '@assets/NFT/NFT-placeholder.png'

import './MyCollection.css'

const SELECTED_NETWORK_ID = process.env.REACT_APP_SELECTED_NETWORK_ID || ''

export const MyCollection = () => {
  const { chainId } = useWeb3React()
  const isDefaultNetworkSelected = useMemo(
    () => chainId?.toString() === SELECTED_NETWORK_ID,
    [chainId]
  )

  const bannerSlides = [
    {
      id: '1',
      image: NFTImage
    },
    {
      id: '2',
      image: NFTImage
    }
  ]

  return (
    <div className="nfts-page page my-collection-page">
      <MyCollectionBanner
        bannerSlides={bannerSlides}
        title={'My collection'}
        isMyCollection
      />
      <section className={'nft-list-section'}>
        <Container>
          {isDefaultNetworkSelected ? (
            <MyCollectionNftListBlock />
          ) : (
            <WrongNetworkBlock type={'NFT list'} />
          )}
        </Container>
      </section>
    </div>
  )
}

export default MyCollection

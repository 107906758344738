import { instanceMetaMessenger } from '@api/config'
import { IProjectLaunchData } from '@api/launchProject/types'

const PROJECT_HASH = '2fde395696d0116fe70d707744935e46'

export const launchProject = async (
  project: IProjectLaunchData
): Promise<boolean | any> => {
  const { data } = await instanceMetaMessenger.post<{ error?: any }>(
    '/storeIntakeForm',
    {
      projectHash: PROJECT_HASH,
      project: {
        userName: project.first_name,
        lastName: project.last_name,
        userEmail: project.email,
        telegram: project.telegram,
        projectType: project.project_type,
        projectName: project.project_name,
        projectWebsite: project.project_website,
        projectTelegram: project.project_telegram,
        projectTwitter: project.project_twitter
      }
    }
  )

  return data?.error || true
}

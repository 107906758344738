import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { useFormikContext } from 'formik'

import { SiteButton } from '@/components'

import { LaunchFormValues } from '@components/layout/LaunchModal/LaunchForm'

import './StepPersonal.scss'

interface IStepPersonalProps {
  continueHandler: () => void
}

export const StepPersonal = ({
  continueHandler
}: IStepPersonalProps): JSX.Element => {
  const { getFieldProps, touched, errors } =
    useFormikContext<LaunchFormValues>()

  const isValidFirstStep = useMemo(
    () =>
      touched.first_name &&
      !errors.first_name &&
      touched.last_name &&
      !errors.last_name &&
      touched.email &&
      !errors.email,
    [errors, touched]
  )

  return (
    <div className="step-personal">
      <h2 className="title">Your Details</h2>
      <Form.Group controlId="first_name">
        <Form.Label>First Name*</Form.Label>
        <Form.Control
          type="text"
          placeholder="First Name"
          {...getFieldProps('first_name')}
          isValid={touched.first_name && !errors.first_name}
          isInvalid={touched.first_name && !!errors.first_name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.first_name}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="last_name">
        <Form.Label>Last Name*</Form.Label>
        <Form.Control
          type="text"
          placeholder="Last Name"
          {...getFieldProps('last_name')}
          isValid={touched.last_name && !errors.last_name}
          isInvalid={touched.last_name && !!errors.last_name}
        />
        <Form.Control.Feedback type="invalid">
          {errors.last_name}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label>Email* </Form.Label>
        <Form.Control
          type="email"
          placeholder="username@email.com"
          {...getFieldProps('email')}
          isValid={touched.email && !errors.email}
          isInvalid={touched.email && !!errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {errors.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="telegram">
        <Form.Label>Telegram</Form.Label>
        <Form.Control
          type="text"
          placeholder="@"
          {...getFieldProps('telegram')}
          isValid={touched.telegram && !errors.telegram}
          isInvalid={touched.telegram && !!errors.telegram}
        />
        <Form.Control.Feedback type="invalid">
          {errors.telegram}
        </Form.Control.Feedback>
      </Form.Group>

      <SiteButton
        type="button"
        size="large"
        onClick={() => continueHandler()}
        color={'YELLOW'}
        disabled={!isValidFirstStep}
      >
        Continue
      </SiteButton>
    </div>
  )
}

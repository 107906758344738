import axios, { CancelToken } from 'axios'

import { ICalendarEvent } from '@api/calendar/types'

import { sendExceptionReport } from '@utils/errors'

import { instance as API } from '../config'

export const _getCalendarEvents = async (
  cancelToken?: CancelToken
): Promise<ICalendarEvent[]> => {
  try {
    const { data } = await API.get<ICalendarEvent[]>('/get-calendar-events', {
      cancelToken
    })

    return data
  } catch (err) {
    if (err instanceof axios.Cancel) {
      throw err
    }
    sendExceptionReport(err)
    return []
  }
}

import { JsonRpcResponse } from 'web3-core-helpers/types'

import { walletconnect } from '@/connectors'

import { sendExceptionReport } from './errors'
import { web3NoAccount } from './web3'

export const createWalletSignature = async (
  data: string
): Promise<string | null> => {
  const isWalletConnect = !!walletconnect.walletConnectProvider
  const web3 = isWalletConnect
    ? walletconnect.walletConnectProvider
    : web3NoAccount.givenProvider
  const account = isWalletConnect ? web3.accounts[0] : web3.selectedAddress

  let signature = ''
  if (!web3) {
    return null
  }

  const params = [data, account]
  // @ts-ignore
  await new Promise(async (resolve, reject) => {
    await web3.send(
      {
        method: 'personal_sign',
        params,
        from: account
      },
      (error: Error | null, result?: JsonRpcResponse) => {
        if (error) return sendExceptionReport(error)
        if (result?.error) return sendExceptionReport(result.error)

        signature = result?.result
        resolve(signature)
      }
    )
  })

  return signature
}

import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

import { NFTBannerSlider } from '@/components'

import CollectionLogo from '@assets/logo-dogechain.png'
interface IMyCollectionBannerProps {
  bannerSlides: Array<any>
  title: string
  isMyCollection?: boolean
}

export const MyCollectionBanner = ({
  bannerSlides,
  title,
  isMyCollection
}: IMyCollectionBannerProps) => {
  return (
    <section className="nfts-page__top-banner">
      <div className="background">
        {!!bannerSlides.length && !isMyCollection && (
          <NFTBannerSlider slides={bannerSlides} />
        )}
      </div>
      <Container>
        <Row className="hero-row">
          <div className="hero-row__main text-center">
            <div className="hero-row__logo">
              <Image src={CollectionLogo} alt={'logo'} />
            </div>
            <h2 className="title">{title}</h2>
            {!isMyCollection && (
              <p className="subtitle">
                1000 Unique NFT’s from your Top Doge HODLR
              </p>
            )}
          </div>
        </Row>
      </Container>
    </section>
  )
}

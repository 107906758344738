import React from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { CollectionCard } from '@components/index'

import { ICalendarPhaseWithCollection } from '@api/calendar/types'

import { useMediaDimensions } from '@hooks/useMediaDimensions'

import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import './DropsSlider.scss'

interface IDropsSliderProps {
  phases: ICalendarPhaseWithCollection[]
}

SwiperCore.use([Pagination])

export const DropsSlider = ({ phases }: IDropsSliderProps) => {
  const { currentDimension } = useMediaDimensions()

  const slidesMapping: Record<string, number> = {
    xl: 5,
    lg: 4,
    md: 3,
    sm: 2
  }

  return (
    <div className={'nft-slider'}>
      <Swiper
        className="projects-slider-slides"
        spaceBetween={32}
        autoplay={true}
        slidesPerView={1}
        touchEventsTarget="wrapper"
        breakpoints={{
          // when window width is >= 576px
        576: {
          slidesPerView: 1,
        },
          // when window width is >= 768px
        768: {
          slidesPerView: 2,
        },
          // when window width is >= 1024px
        1024: {
          spaceBetween: 10,
          slidesPerView: 3,
        },
        1280: {
          slidesPerGroup: 2,
          slidesPerView: 4,
        },
      }}
      >
        {phases.map(({ name, collection, image }, index) => (
          <SwiperSlide key={name}>
            <CollectionCard
              id={collection.id}
              name={name}
              image={image}
              collectionName={collection.name}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

import React from 'react'

import { DynamicImage } from '../../index'
import { TeamMember } from '../types'

import './TeamCard.css'

export const TeamCard = ({ avatar, name, bio }: TeamMember) => {
  return (
    <div className="team-card">
      <div className="team-card__inner">
        <div className="team-card__image-box">
          <DynamicImage
            roundedCircle
            loading="lazy"
            path={avatar}
            alt={name}
            className="team-card__image"
          />
        </div>
        <h4 className="team-card__name">{name}</h4>
        <p className="team-card__bio">{bio}</p>
      </div>
    </div>
  )
}

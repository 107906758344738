import React from 'react'
import { Container } from 'react-bootstrap'

import { Accordion } from '@/components'

import './FAQ.scss'

interface IFaqProps {}

const faqItems = [
  {
    // eslint-disable-next-line
    title: 'What is an NFT?',
    body: (
      <span>
        NFT stands for 'non-fungible token'. When something is fungible, like a
        dollar bill, it is equivalent to, and can thus be exchanged for, any
        other dollar bill. In contrast, a non-fungible token is a unique asset
        in digital form that cannot be exchanged for any other NFT. This means
        that every NFT is a 'one-of-a-kind' item. NFTs are transferred from one
        owner to another using blockchain technology, which creates a digital
        trail from seller to buyer that verifies the transaction. This encodes
        the unique ownership rights to the buyer (new owner).
      </span>
    )
  },
  {
    // eslint-disable-next-line
    title: 'How can I get Allowlisted?',
    body: (
      <span>
        To get Allowlisted, you must click on the NFT project you are interested
        in and apply there.
      </span>
    )
  },
  {
    // eslint-disable-next-line
    title: 'How do I get an NFT?',
    body: (
      <ul>
        <li>You must apply to be Allowlisted.</li>
        <li>
          If you are accepted and granted a Allowlist spot, you must wait for
          the NFT sale to begin. Swap funds for the NFT.
        </li>
        <li>And you’re done!</li>
      </ul>
    )
  },
  {
    // eslint-disable-next-line
    title: 'Where can I learn about upcoming drops?',
    body: (
      <span>
        You can check on Upcoming Drops and our Calendar. You can also stay up
        to date by following us on Twitter or by joining our Telegram channel.
      </span>
    )
  }
]

export const Faq = ({}: IFaqProps): JSX.Element => {
  return (
    <div className="faq page">
      <Container>
        <h1 className="title">FAQ</h1>

        <Accordion items={faqItems} />
      </Container>
    </div>
  )
}

export default Faq

import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { RoutesPaths } from '@router/constants'

import { DynamicImage, SiteButton } from '@/components'

import './AboutSection.scss'

export const AboutSection = () => {
  return (
    <section className={'about-section'}>
      <Container>
        <Row>
          <Col md={4}>
            <div className="about-item">
              <div className="about-item__title">
                <DynamicImage path={'Landing/about-ico1.svg'} />
                NFT’s FOR THE DOGE COMMUNITY
              </div>
              <div className="about-item__text">Wen moon?</div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-item">
              <div className="about-item__title">
                <DynamicImage path={'Landing/about-ico2.svg'} />
                ART FOR DOGE CHAIN
              </div>
              <div className="about-item__text">
                Collect art curated for the Doge Chain Community
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="about-item">
              <div className="about-item__title">
                <DynamicImage path={'Landing/about-ico3.svg'} />
                DIAMOND HAND AIRDROPS
              </div>
              <div className="about-item__text">
                Hold an NFT, Get Airdropped
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

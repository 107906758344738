import React, { useMemo, useState } from 'react'
import { Image } from 'react-bootstrap'
import classNames from 'classnames'

import {
  CollectionStatusesProps,
  CollectionStatusProp,
  LoadingWrap,
  NormalizedCollectionInfo,
  SiteButton
} from '@/components'

import { WhitelistModal } from '@components/CollectionDetails'

import { WhitelistStatus, WhitelistStatuses } from '@api/whitelist/types'

import WLIcon from '@assets/account.svg'
import WLNotPassedIcon from '@assets/account-warning.svg'

interface IWhitelistBlockProps {
  statusMessage: CollectionStatusProp
  collection: NormalizedCollectionInfo
  whitelistStatus: WhitelistStatus
  whitelistLoading: boolean
}

export const WhitelistBlock = ({
  statusMessage,
  collection,
  whitelistStatus,
  whitelistLoading
}: IWhitelistBlockProps) => {
  const [show, setShow] = useState<boolean>(false)

  const whitelistStatusMessage = useMemo(() => {
    switch (whitelistStatus) {
      case WhitelistStatuses.passed:
        return 'Wallet is whitelisted'
      case WhitelistStatuses.in_review:
        return 'Wallet whitelisting is pending'
      default:
        return 'Wallet is NOT whitelisted'
    }
  }, [whitelistStatus])

  return (
    <div className="whitelist-block">
      <LoadingWrap loading={whitelistLoading}>
        {statusMessage === CollectionStatusesProps['Registration Open'] &&
        (whitelistStatus === WhitelistStatuses.not_submitted ||
          !whitelistStatus) ? (
          <SiteButton
            onClick={() => setShow(true)}
            size="large"
            color={'YELLOW'}
          >
            Apply for Whitelist
          </SiteButton>
        ) : (
          <div
            className={classNames('whitelist-badge', {
              [`${whitelistStatus}`]: !!whitelistStatus,
              invalid: !whitelistStatus
            })}
          >
            <Image
              src={
                [
                  WhitelistStatuses.passed,
                  WhitelistStatuses.in_review
                ].includes(whitelistStatus as WhitelistStatuses)
                  ? WLIcon
                  : WLNotPassedIcon
              }
            />
            <span>{whitelistStatusMessage}</span>
          </div>
        )}
      </LoadingWrap>

      <WhitelistModal show={show} collection={collection} setShow={setShow} />
    </div>
  )
}

import React from 'react'
import { INFTToken, NFTMetadata } from '@store/types'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { NormalizedCollectionInfo } from '../../CollectionPresale'
import { NftCard } from '../NFTCard'

import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import './NFTSlider.css'

interface INFTSliderProps {
  NFTArray: INFTToken[]
  collection: NormalizedCollectionInfo
}

SwiperCore.use([Pagination])

export const NFTSlider = ({ NFTArray, collection }: INFTSliderProps) => {
  const paginationOptions = {
    el: '.nft-slider__pagination'
  }

  return (
    <div className={'nft-slider'}>
      <Swiper
        className="projects-slider-slides"
        loop={true}
        spaceBetween={32}
        slidesPerView={2}
        touchEventsTarget="wrapper"
        pagination={paginationOptions}
      >
        {NFTArray.map(({ id, phase, image, ...token }, index) => (
          <SwiperSlide key={id}>
            <NftCard
              {...token}
              phase={phase}
              asset_id={id}
              id={id}
              collection_id={token.collection?.id!}
              image={image}
              collection_name={token.collection?.name!}
              fundToken={token.collection?.presale?.fund_token!}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="nft-slider__pagination" />
    </div>
  )
}

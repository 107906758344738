import React from 'react'

import './GasTracker.scss'

interface IGasTrackerProps {}

export const GasTracker = ({}: IGasTrackerProps): JSX.Element => {
  return (
    <div className="gas-tracker">
      <div className="gas-tracker__title">Gas Tracker</div>

      <div className="gas-tracker__wrapper">
        <div className="gas-tracker-item">
          <div className="gas-tracker-item__label">Fast</div>
          <div className="gas-tracker-item__value">50 GWEI</div>
        </div>
        <div className="gas-tracker-item">
          <div className="gas-tracker-item__label">Normal</div>
          <div className="gas-tracker-item__value">50 GWEI</div>
        </div>
        <div className="gas-tracker-item">
          <div className="gas-tracker-item__label">Slow</div>
          <div className="gas-tracker-item__value">50 GWEI</div>
        </div>
      </div>
    </div>
  )
}

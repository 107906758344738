import { ITimeLineItem } from '@components/common/TimeLine'

export const ROADMAP_DATA: ITimeLineItem[] = [
  {
    year: 2022,
    timePeriod: 'February',
    title: 'NFT Giveaway',
    description:
      'Assemble in Discord & Submit a derivative in your style. By community vote, let the best design win.'
  },
  {
    year: 2022,
    timePeriod: 'March 12',
    title: 'Merch',
    description:
      'Assemble in Discord & Submit a derivative in your style. By community vote, let the best design win.'
  },
  {
    year: 2022,
    timePeriod: 'April 24',
    title: 'Metaverse Planning begins',
    description:
      'Assemble in Discord & Submit a derivative in your style. By community vote, let the best design win.'
  },
  {
    year: 2022,
    timePeriod: 'Summer',
    title: 'Comic Book drafting begins',
    description:
      'Assemble in Discord & Submit a derivative in your style. By community vote, let the best design win.'
  }
]

import { AxiosError } from 'axios'

import { sendExceptionReport } from '@utils/errors'

import { instance as API } from '../config'

import {
  GetWhitelistArgs,
  GetWhitelistDataReturns,
  WhitelistSuccessResponse
} from './types'

export const getWhitelistData = async ({
  account,
  collectionId
}: GetWhitelistArgs): Promise<GetWhitelistDataReturns | null> => {
  try {
    const { data } = await API.get<WhitelistSuccessResponse>(
      '/get-whitelist-data',
      {
        params: {
          wallet_address: account,
          collection_id: collectionId
        }
      }
    )

    return {
      status: data.status,
      phases: data.phases
    }
  } catch (err) {
    if ((err as AxiosError).response?.status !== 404) {
      sendExceptionReport(err)
    }
    return null
  }
}

import * as yup from 'yup'

const websiteRegex =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm

export const getValidationSchema = () => {
  return yup.object().shape({
    first_name: yup.string().trim().required('First name required'),
    last_name: yup.string().trim().required('Last name required'),
    email: yup
      .string()
      .trim()
      .email('Write email in correct format')
      .required('Email is required'),
    project_name: yup.string().trim().required('Project name required'),
    project_website: yup
      .string()
      .trim()
      .matches(websiteRegex, {
        message: 'Please fill correct website url',
        excludeEmptyString: true
      })
      .required('Project website required'),
    project_telegram: yup.string().trim().required('Project telegram required')
  })
}

import React, { useEffect, useMemo } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import { useWeb3React } from '@web3-react/core'

import {
  AccountCheckWrapper,
  CollectionStatusesProps,
  LoadingWrap,
  WhitelistForm,
  WrongNetworkBlock
} from '@components/index'

import { useCollectionsState } from '@hooks/useCollections'

import './Whitelist.css'

interface ParamTypes {
  collection_id: string
}

export const Whitelist = () => {
  const { collection_id: id } = useParams<ParamTypes>()
  const history = useHistory()
  const {
    currentCollection: collection,
    getCollectionById,
    loading
  } = useCollectionsState()

  useEffect(() => {
    if (id !== collection?.id || !collection) {
      getCollectionById(id)
    }
  }, [id, collection])

  useEffect(() => {
    if (
      collection &&
      collection.id === id &&
      collection.presale.statusMessage !==
        CollectionStatusesProps['Registration Open']
    ) {
      history.replace(`/collections/${id}`)
    }
  }, [collection, id])

  const { chainId } = useWeb3React()

  const isCollectionNetworkSelected = useMemo(
    () => (collection ? collection.chainId === chainId?.toString() : false),
    [collection, chainId]
  )

  return (
    <main className={'page whitelist-page'}>
      <Container>
        <AccountCheckWrapper>
          <LoadingWrap loading={loading}>
            {isCollectionNetworkSelected ? (
              collection && <WhitelistForm collection={collection} />
            ) : (
              <WrongNetworkBlock type={'Whitelist'} />
            )}
          </LoadingWrap>
        </AccountCheckWrapper>
      </Container>
    </main>
  )
}

export default Whitelist

import { useEffect, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'

import { IEventData } from '@components/Calendar/types'

import { _getCalendarEvents } from '@api/calendar'
import {
  ICalendarPhase,
  ICalendarPhaseWithCollection
} from '@api/calendar/types'

import { useIsMounted } from '@hooks/useIsMounted'

export const useCalendarData = () => {
  const [calendarEvents, setCalendarEvents] = useState<IEventData[]>([])
  const [phases, setPhases] = useState<ICalendarPhaseWithCollection[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const isMountedRef = useIsMounted()

  useEffect(() => {
    const getCalendarData = async () => {
      try {
        setLoading(true)
        const events = await _getCalendarEvents()

        const mappedEvents: IEventData[] = events.map(
          ({ name, social_links, image, description, date, collection }) => {
            return {
              name,
              description,
              image,
              date,
              socialLinks: social_links,
              artist: collection.artist,
              link: generatePath(RoutesPaths.COLLECTION_DETAILS, {
                collection_id: collection.id
              })
            }
          }
        )

        const allPhases: Array<ICalendarPhaseWithCollection> = events
          .map((event) => {
            return event.phases.map((phase) => {
              return { ...phase, collection: event.collection }
            })
          })
          .flat()
        isMountedRef.current && setCalendarEvents(mappedEvents)
        isMountedRef.current && setPhases(allPhases)
        isMountedRef.current && setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }

    getCalendarData()
  }, [])

  return {
    calendarEvents,
    phases,
    loading
  }
}

import React, { useState } from 'react'
import { Container, Tab, Tabs } from 'react-bootstrap'

import './CollectionsTabsSection.scss'

enum TabsEnum {
  JUST_RELEASED = 'JUST RELEASED',
  UPCOMING_DROPS = 'UPCOMING DROPS',
  LATEST_VIDEO = 'LATEST VIDEO'
}

export const CollectionsTabsSection = () => {
  const [key, setKey] = useState<TabsEnum | null>(TabsEnum.JUST_RELEASED)
  return (
    <section className={'collections-tabs-section'}>
      <Container>
        <h2 className="title mb-5">JUST RELEASED</h2>
      </Container>
    </section>
  )
}

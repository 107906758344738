import { INFTToken } from '@store/types'

import { _getNftsData } from '@/api'

import { ISubgraphToken } from '@api/subgraph/nfts/types'

import { toChecksumAddress } from '@utils/string'

export const getTokensData = async (
  tokens: ISubgraphToken[]
): Promise<INFTToken[]> => {
  const tokensQueryObject = tokens.map((item) => ({
    id: item.tokenId,
    address: toChecksumAddress(item.phase.collection)
  }))

  const tokensMetadata = await _getNftsData(tokensQueryObject)

  const nftTokens: INFTToken[] = tokens.map((token) => {
    let tokenCopy: any = Object.assign({}, token)
    tokensMetadata.map((metadata) => {
      if (
        (metadata.token_id || metadata.tokenId) === token.tokenId &&
        metadata.address === toChecksumAddress(token.phase.collection)
      ) {
        tokenCopy = Object.assign(metadata, token)
      }
    })

    return tokenCopy as INFTToken
  })

  return nftTokens
}

import React, { Dispatch, SetStateAction, useState } from 'react'

import { WhitelistStatus } from '../../../types'
import { AsideSection } from '../AsideSection'
import { IAsideItem } from '../types'

interface IStatusFilterProps {
  statuses: typeof WhitelistStatus
  setFilter: Dispatch<SetStateAction<string>>
}

export const StatusFilter = ({ statuses, setFilter }: IStatusFilterProps) => {
  const [items, setItems] = useState<IAsideItem[]>(() => {
    return ['All', ...Object.values(statuses)].map((item) => ({
      text: item,
      onClick: () => {
        setFilter(item as string)
      },
      selected: item === 'All' ? true : false
    })) as IAsideItem[]
  })

  return <AsideSection title={'Status'} items={items} setItems={setItems} />
}

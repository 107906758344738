import React, { useEffect } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { generatePath, Link, useParams } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'
import { INFTToken, NFTMetadata } from '@store/types'

import {
  Accordion,
  BackLink,
  LoadingWrap,
  NormalizedCollectionInfo,
  ShareWithBlock,
  SiteButton
} from '@components/index'

import { useGetNFTTokenById } from '@contracts/hooks/NFT/useGetNFTTokenById'
import { useNFT } from '@contracts/hooks/NFT/useNFT'

import { useCollectionsState } from '@hooks/useCollections'
import { useMediaDimensions } from '@hooks/useMediaDimensions'

import { ReactComponent as LinkArrow } from '@assets/link-arrow.svg'
import NFTImage from '@assets/NFT/NFT-placeholder.png'
import CollectionIco from '@assets/NFT/project-ico.png'

import './NFTPage.css'

interface ParamTypes {
  collection_id: string
  asset_id: string
}

const NFT_COLLECTION_INFO = {
  features: [
    '1x Amazing feature',
    '4x Awesome feature',
    '3x Beautiful features'
  ]
}

const faqItems = [
  {
    // eslint-disable-next-line
    title: 'What is an NFT?',
    body: (
      <span>
        NFT stands for 'non-fungible token'. When something is fungible, like a
        dollar bill, it is equivalent to, and can thus be exchanged for, any
        other dollar bill. In contrast, a non-fungible token is a unique asset
        in digital form that cannot be exchanged for any other NFT. This means
        that every NFT is a 'one-of-a-kind' item. NFTs are transferred from one
        owner to another using blockchain technology, which creates a digital
        trail from seller to buyer that verifies the transaction. This encodes
        the unique ownership rights to the buyer (new owner).
      </span>
    )
  },
  {
    // eslint-disable-next-line
    title: 'How can I get Allowlisted?',
    body: (
      <span>
        To get Allowlisted, you must click on the NFT project you are interested
        in and apply there.
      </span>
    )
  },
  {
    // eslint-disable-next-line
    title: 'How do I get an NFT?',
    body: (
      <ul>
        <li>You must apply to be Allowlisted.</li>
        <li>
          If you are accepted and granted a Allowlist spot, you must wait for
          the NFT sale to begin. Swap funds for the NFT.
        </li>
        <li>And you’re done!</li>
      </ul>
    )
  },
  {
    // eslint-disable-next-line
    title: 'Where can I learn about upcoming drops?',
    body: (
      <span>
        You can check on Upcoming Drops and our Calendar. You can also stay up
        to date by following us on Twitter or by joining our Telegram channel.
      </span>
    )
  }
]

export const NFTPage = () => {
  const { asset_id, collection_id } = useParams<ParamTypes>()
  const { lg } = useMediaDimensions()

  const {
    currentCollection: collection,
    getCollectionById,
    loading
  } = useCollectionsState()

  const { currentNFT } = useNFT(
    collection?.presale?.reward_token?.address || ''
  )

  const { loading: tokenLoading } = useGetNFTTokenById(asset_id)

  useEffect(() => {
    if (collection?.id !== collection_id || !collection) {
      getCollectionById(collection_id)
    }
  }, [collection, collection_id])

  const NFTHeading = ({
    collection,
    currentNFT
  }: {
    collection: NormalizedCollectionInfo
    currentNFT: INFTToken
  }) => {
    return (
      <div className="nft-token__heading">
        <div className="nft-token__collection">
          <div className="nft-token__collection-logo">
            <Image
              src={collection?.assets?.logo_image_url || CollectionIco}
              roundedCircle={true}
            />
          </div>
          <div className="nft-token__collection-name">
            <Link
              to={generatePath(RoutesPaths.COLLECTION_DETAILS, {
                collection_id: collection?.id
              })}
              className={'text-decoration-none'}
            >
              {collection?.artist.name}
            </Link>
          </div>
        </div>

        <div className="nft-token__heading-text">
          <h2 className="title">
            {
              // eslint-disable-next-line
              collection.name || "The 7's Club "
            }
          </h2>

          <div className="nft-token__amount-stat">
            {currentNFT.tokenId} / <span>{collection.max_nft_amount}</span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="nft-token-page page">
      <Container>
        {collection && (
          <BackLink
            to={generatePath(RoutesPaths.COLLECTION_DETAILS, {
              collection_id: collection.id
            })}
          >
            Collection
          </BackLink>
        )}
      </Container>
      <section className="nft-token-section">
        <Container>
          <Row className="g-5">
            <LoadingWrap loading={loading || tokenLoading}>
              {collection && currentNFT ? (
                <>
                  {!lg && (
                    <NFTHeading
                      collection={collection}
                      currentNFT={currentNFT}
                    />
                  )}
                  <Col lg={{ span: 6 }}>
                    <div className="nft-token-asset-box">
                      <Image
                        className="nft-token-asset"
                        src={currentNFT?.image_url || NFTImage}
                      />
                    </div>
                  </Col>
                  <Col lg={{ span: 6 }}>
                    <div className="nft-token-content">
                      {lg && (
                        <NFTHeading
                          collection={collection}
                          currentNFT={currentNFT}
                        />
                      )}
                      <div className="nft-token__description">
                        <p>
                          {currentNFT?.metadata?.description ||
                            // eslint-disable-next-line
                            'Glauber Contessoto, also known as “The Dogecoin Millionaire” fell in love with Dogecoin in early 2021. He put his life savings into the cryptocurrency and 69 days later became a millionaire. This is his first NFT collection release.'}
                        </p>
                      </div>

                      <div className="mt-5 d-flex justify-content-md-start justify-content-center">
                        <SiteButton
                          href={`${collection.opensea_link}/${asset_id}`}
                          size={'large'}
                          color={'DARK'}
                          bordered
                        >
                          View on Opensea
                          <LinkArrow />
                        </SiteButton>
                      </div>

                      <div className="nft-token__social-share">
                        <ShareWithBlock text={`NFT from ${collection.name}`} />
                      </div>

                      <div className={'nft-token__faq'}>
                        <h2 className="title mb-5">FAQ</h2>
                        <Accordion items={faqItems} />
                      </div>
                    </div>
                  </Col>
                </>
              ) : (
                <div>Something went wrong...</div>
              )}
            </LoadingWrap>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default NFTPage

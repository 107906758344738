import React from 'react'
import { Container } from 'react-bootstrap'

import './AboutUs.scss'

export const AboutUs = () => {
  return (
    <main className={'page pt-5 pb-5 page-about-us'}>
      <Container>
        <h1 className="title">About us</h1>

        <div className="caption">
          <p className={'text-black'}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex laborum
            necessitatibus temporibus! Architecto dicta dolorum laboriosam
            pariatur, provident sed ullam. Corporis doloribus ea labore maxime
            nostrum quas quisquam quos repudiandae. Lorem ipsum dolor sit amet,
            consectetur adipisicing elit. Accusantium deserunt dolorem
            doloremque, ea eligendi eos et excepturi fuga harum laborum
            laudantium libero maiores perferendis praesentium qui quisquam
            reprehenderit tempore voluptate.
          </p>

          <p className={'text-black'}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ex laborum
            necessitatibus temporibus! Architecto dicta dolorum laboriosam
            pariatur, provident sed ullam. Corporis doloribus ea labore maxime
            nostrum quas quisquam quos repudiandae.
          </p>
        </div>
      </Container>
    </main>
  )
}

export default AboutUs

import React, { useCallback, useMemo, useState } from 'react'
import { Image } from 'react-bootstrap'
import {
  useNetwork,
  useWalletContext
} from '@firestarter-private/firestarter-library'
import {
  defaultEVMNetworkId,
  NetworkId,
  NetworkInfo
} from '@firestarter-private/firestarter-library/lib/constants/networks'

import { RoundButton, RoundButtonProps } from '../RoundButton'
import { Spinner } from '../Spinner'

type Props = Pick<RoundButtonProps, 'color' | 'size' | 'wide' | 'className'> & {
  text?: React.ReactNode
  networkId: NetworkId
  error?: any
}

const AddNetworkButton = ({
  color,
  size,
  wide,
  className,
  text,
  networkId
}: Props) => {
  const [loading, setLoading] = useState(false)
  const { getNetwork, switchNetwork, currentNetworkId } = useNetwork()
  const { account } = useWalletContext()

  const networkToSwitch = useMemo(
    () => getNetwork(networkId),
    [getNetwork, networkId]
  )

  const addChain = async (network: NetworkInfo) => {
    const ethereum = (window as any).ethereum

    ethereum
      .request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x' + Number(network.networkId).toString(16),
            chainName: network.name,
            nativeCurrency: {
              name: network.nativeCurrency.name,
              symbol: network.nativeCurrency.symbol,
              decimals: 18
            },
            rpcUrls: [network.rpcUrl],
            blockExplorerUrls: [network.explorerUrl]
          }
        ]
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  const displayName = useMemo(
    () => networkToSwitch.userFriendlyName,
    [networkToSwitch]
  )

  const addNetwork = useCallback(async () => {
    if (!loading) {
      setLoading(true)
      try {
        await switchNetwork(networkId)
      } catch (e) {
        await addChain(networkToSwitch)
      }
      setLoading(false)
    }
  }, [switchNetwork, loading, account, currentNetworkId])

  const getNetworkIcon = (network: NetworkInfo): string => {
    return `/networks/${network.icon}.png`
  }

  return (
    <RoundButton
      className={`add-network-button ${className}`}
      type="button"
      color={color}
      size={size}
      wide={wide}
      disabled={loading}
      onClick={addNetwork}
    >
      <span>
        {text} {displayName}
      </span>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Image
            className="ms-1"
            src={getNetworkIcon(networkToSwitch)}
            width={22}
            height={21}
          />
        </>
      )}
    </RoundButton>
  )
}

AddNetworkButton.defaultProps = {
  color: 'LIGHT',
  size: 'small',
  wide: false,
  text: 'Switch to',
  networkId: defaultEVMNetworkId
}

export { AddNetworkButton }

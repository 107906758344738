import { useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'
import { ActionType, AppState } from '@store/types'

import { getCollectionNfts, getNftToken } from '@api/subgraph/nfts/queries'
import { ISubgraphGetTokensResponse } from '@api/subgraph/nfts/types'

import { getTokensData } from '@contracts/utils/getTokensData'

import { useDispatch } from '@hooks/useDispatch'
import { useSelector } from '@hooks/useSelector'

import { sendExceptionReport } from '@utils/errors'

import { ContractAddress } from '../../address'

import { useGetTokenMetadataById } from './useGetTokenMetadataById'

export const useGetNFTTokenById = (tokenId: string) => {
  const dispatch = useDispatch()
  const [loadingTokenData, setLoadingTokenData] = useState<boolean>(false)
  const { fetchingCurrentToken } = useSelector<AppState['NFT']>(
    (state) => state.NFT
  )

  const { loading, refetch: refetchToken } =
    useQuery<ISubgraphGetTokensResponse>(getNftToken, {
      variables: {
        id: tokenId
      },
      onCompleted: async (data) => {
        dispatch({ type: ActionType.SET_FETCHING_CURRENT_NFT, payload: true })
        setLoadingTokenData(true)

        try {
          const nftTokens = await getTokensData(data.tokens)

          dispatch({
            type: ActionType.SET_CURRENT_NFT,
            payload: nftTokens[0]
          })
          dispatch({
            type: ActionType.SET_FETCHING_CURRENT_NFT,
            payload: false
          })
          setLoadingTokenData(false)
          return
        } catch (e) {
          sendExceptionReport(e)
          dispatch({
            type: ActionType.SET_CURRENT_NFT,
            payload: null
          })
          dispatch({
            type: ActionType.SET_FETCHING_CURRENT_NFT,
            payload: false
          })
          setLoadingTokenData(false)
          return
        }
      }
    })

  return {
    refetchToken,
    loading: loading || loadingTokenData || fetchingCurrentToken
  }
}

import {
  REACT_APP_BNB_RPC_URL,
  REACT_APP_DOGECHAIN_RPC_URL,
  REACT_APP_ENV,
  REACT_APP_ETH_RPC_URL,
  REACT_APP_POLYGON_RPC_URL,
  REACT_APP_SELECTED_NETWORK_ID
} from './envvars'

export enum NetworksIDs {
  matic = '137',
  maticmum = '80001',
  eth = '1',
  rin = '4',
  bnb = '56',
  bnbt = '97',
  dogichain = '2000'
}

export enum NetworkNames {
  matic = 'Matic Mainnet',
  maticmum = 'Matic Testnet Mumbai',
  eth = 'Ethereum Mainnet',
  rin = 'Ethereum Testnet Rinkeby',
  bnb = 'Binance Smart Chain Mainnet',
  bnbt = 'Binance Smart Chain Testnet',
  dogichain = 'Dogichain Mainnet'
}

export enum NetworkUserFriendlyNames {
  matic = 'Polygon',
  maticmum = 'Mumbai',
  eth = 'Ethereum',
  rin = 'Rinkeby',
  bnb = 'Binance',
  bnbt = 'BSC Testnet',
  dogichain = 'Dogichain'
}

export type NetworkName = NetworkNames[number]
export type NetworkId = NetworksIDs[number]
export type NetworkUserFriendlyName = NetworkUserFriendlyNames[number]

export interface NetworkCurrency {
  name: string
  symbol: string
  decimals: number
}

export interface NetworkInfo {
  name: NetworkName
  icon: string
  userFriendlyName: NetworkUserFriendlyName
  networkId: NetworkId
  nativeCurrency: NetworkCurrency
  rpcUrl: string
  privateRPC?: string
  explorerUrl: string
  production: boolean
  isActive: boolean
  color: string
}

export type NetworksList = Record<NetworkId, NetworkInfo>

export const networks: NetworksList = {
  [NetworksIDs.maticmum]: {
    name: NetworkNames.maticmum,
    icon: 'polygon',
    userFriendlyName: NetworkUserFriendlyNames.maticmum,
    networkId: NetworksIDs.maticmum,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrl: 'https://rpc-mumbai.maticvigil.com/',
    explorerUrl: 'https://mumbai.polygonscan.com/',
    production: false,
    isActive: true,
    color: '#8248e5'
  },
  [NetworksIDs.matic]: {
    name: NetworkNames.matic,
    icon: 'polygon',
    userFriendlyName: NetworkUserFriendlyNames.matic,
    networkId: NetworksIDs.matic,
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrl: REACT_APP_POLYGON_RPC_URL ?? 'https://polygon-rpc.com/',
    explorerUrl: 'https://polygonscan.com/',
    production: true,
    isActive: true,
    color: '#8248e5'
  },
  [NetworksIDs.eth]: {
    name: NetworkNames.eth,
    icon: 'eth',
    userFriendlyName: NetworkUserFriendlyNames.eth,
    networkId: NetworksIDs.eth,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrl: REACT_APP_ETH_RPC_URL ?? 'https://main-light.eth.linkpool.io/',
    explorerUrl: 'https://etherscan.io',
    production: true,
    isActive: true,
    color: '#627EEA'
  },
  [NetworksIDs.rin]: {
    name: NetworkNames.rin,
    icon: 'rinkeby',
    userFriendlyName: NetworkUserFriendlyNames.rin,
    networkId: NetworksIDs.rin,
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrl: 'https://rinkeby-light.eth.linkpool.io/',
    explorerUrl: 'https://rinkeby.etherscan.io',
    production: false,
    isActive: true,
    color: '#686868'
  },
  [NetworksIDs.bnb]: {
    name: NetworkNames.bnb,
    icon: 'bsc',
    userFriendlyName: NetworkUserFriendlyNames.bnb,
    networkId: NetworksIDs.bnb,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 8
    },
    rpcUrl: REACT_APP_BNB_RPC_URL ?? 'https://bsc-dataseed.binance.org/',
    explorerUrl: 'https://bscscan.com',
    production: true,
    isActive: false,
    color: '#F8D12F'
  },
  [NetworksIDs.bnbt]: {
    name: NetworkNames.bnbt,
    icon: 'bsc',
    userFriendlyName: NetworkUserFriendlyNames.bnbt,
    networkId: NetworksIDs.bnbt,
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 8
    },
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    explorerUrl: 'https://testnet.bscscan.com/',
    production: false,
    isActive: false,
    color: '#F8D12F'
  },
  [NetworksIDs.dogichain]: {
    name: NetworkNames.dogichain,
    icon: 'dogi',
    userFriendlyName: NetworkUserFriendlyNames.dogichain,
    networkId: NetworksIDs.dogichain,
    nativeCurrency: {
      name: 'wDOGE',
      symbol: 'wDOGE',
      decimals: 8
    },
    rpcUrl: REACT_APP_DOGECHAIN_RPC_URL || 'https://rpc01-sg.dogechain.dog',
    explorerUrl: 'https://explorer.dogechain.dog',
    production: true,
    isActive: true,
    color: '#b1a057'
  }
}

export const defaultEVMNetworkId = REACT_APP_SELECTED_NETWORK_ID as NetworkId
export const defaultEnvironmentNetworkData = networks[defaultEVMNetworkId]

export const defaultEnvironmentNetworkName = networks[defaultEVMNetworkId].name

export const supportedNetworks: Record<NetworkId, NetworkInfo> = Object.entries(
  networks
).reduce((acc, [id, network]) => {
  if (!network.isActive) {
    return acc
  }
  if (REACT_APP_ENV === 'production' && !network.production) {
    return acc
  }

  acc[id] = networks[id]
  return acc
}, {} as Record<NetworkId, NetworkInfo>)

export const getCurrentNetworkId = (): NetworkId => defaultEVMNetworkId

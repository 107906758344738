import React, { useEffect, useMemo } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { generatePath, useParams } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'
import { useWeb3React } from '@web3-react/core'
import classNames from 'classnames'

import { NFTListBlock, WhitelistBlock } from '@components/CollectionDetails'
import {
  AccountCheckWrapper,
  BackLink,
  CollectionStats,
  CollectionStatusesProps,
  CollectionStatusProp,
  IWhitelistPhase,
  LoadingWrap,
  MyCollectionBanner,
  ShareWithBlock,
  SiteButton,
  StatusBadge,
  TimerWrap,
  WrongNetworkBlock
} from '@components/index'

import { WhitelistStatus } from '@api/whitelist/types'

import { useWhitelist } from '@contracts/hooks/useWhitelist'

import { useCollectionsState } from '@hooks/useCollections'
import { useSelector } from '@hooks/useSelector'
import { useWalletConnect } from '@hooks/useWalletConnect'

import CollectionIcon from '@assets/collection/collection-image.png'
import NFTImage from '@assets/NFT/NFT-placeholder.png'

import './CollectionDetails.scss'

const SELECTED_NETWORK_ID = process.env.REACT_APP_SELECTED_NETWORK_ID || ''

interface IParams {
  collection_id: string
}

export const CollectionDetails = () => {
  const { collection_id } = useParams<IParams>()
  const { chainId } = useWeb3React()
  const isDefaultNetworkSelected = chainId?.toString() === SELECTED_NETWORK_ID

  const bannerSlides = [
    {
      id: '1',
      image: NFTImage
    },
    {
      id: '2',
      image: NFTImage
    }
  ]

  const {
    currentCollection: collection,
    getCollectionById,
    loading
  } = useCollectionsState()
  const { onClickWallet } = useWalletConnect()

  const currentPhase = useSelector<IWhitelistPhase>(
    (state) => state.currentCollection?.presale?.currentPhase
  )
  const {
    whitelistStatus,
    allocation,
    remainingAllocation,
    isWhitelisted,
    merkleProof,
    loading: whitelistLoading
  } = useWhitelist(collection?.id, currentPhase?.phase_id?.toString())

  useEffect(() => {
    if (collection?.id !== collection_id || !collection) {
      getCollectionById(collection_id)
    }
  }, [collection_id, collection])

  const statusMessage: CollectionStatusProp =
    collection?.presale.statusMessage || CollectionStatusesProps['Coming Soon']
  const isPrivatePresaleInProgress =
    statusMessage === CollectionStatusesProps['Private Live']
  const isPublicPresaleInProgress =
    statusMessage === CollectionStatusesProps['Live']
  const isComingSoonCollection =
    statusMessage === CollectionStatusesProps['Coming Soon']
  const isWhitelistingInProgress =
    statusMessage === CollectionStatusesProps['Registration Open']
  const isPresaleInProgress =
    isPublicPresaleInProgress || isPrivatePresaleInProgress
  const isCollectionNetworkSelected = collection
    ? chainId?.toString() === collection.chainId
    : false
  const isWhitelistTime =
    statusMessage === CollectionStatusesProps['Registration Open'] ||
    statusMessage === CollectionStatusesProps['Registration Closed']

  const memoStats = useMemo(
    () => (
      <CollectionStats
        collection={collection!}
        isSaleInProgress={isPresaleInProgress}
        whitelistStatus={whitelistStatus as WhitelistStatus}
        allocation={allocation}
        isWhitelisted={isWhitelisted}
        remainingAllocation={remainingAllocation}
        merkleProof={merkleProof}
      />
    ),
    [
      collection,
      isPresaleInProgress,
      whitelistStatus,
      allocation,
      isWhitelisted,
      remainingAllocation,
      merkleProof
    ]
  )

  return (
    <main className={classNames(['page', 'collection-details-page'])}>
      <LoadingWrap loading={loading || !collection}>
        {!!collection &&
          (isPresaleInProgress || isWhitelistTime || isComingSoonCollection ? (
            <div>
              <Container>
                <BackLink to={generatePath(RoutesPaths.ART)}>
                  Collections
                </BackLink>
              </Container>
              <section>
                <Container className={'collection'}>
                  <Row>
                    <Col lg={6}>
                      <div className="collection__image">
                        <Image src={collection.assets.nft_image_url} />
                      </div>
                    </Col>

                    <Col lg={6} className={'mt-4 mt-lg-0'}>
                      <div className="collection__head d-flex align-items-center mb-5">
                        <div className="collection__icon">
                          <Image
                            src={
                              collection.assets.logo_image_url || CollectionIcon
                            }
                            roundedCircle
                          />
                        </div>
                        <div className="collection__head-content">
                          <h1 className="collection__name">
                            {collection.name}
                          </h1>
                          <span>
                            <StatusBadge
                              value={collection.presale.statusMessage}
                            />
                          </span>
                        </div>
                      </div>

                      {isDefaultNetworkSelected && memoStats}

                      {isPresaleInProgress &&
                        collection.presale.public_end_at && (
                          <TimerWrap
                            dueDate={collection.presale.public_end_at}
                            title={'Presale ending'}
                          />
                        )}

                      {isWhitelistingInProgress &&
                        collection.whitelisting.end_at && (
                          <TimerWrap
                            dueDate={collection.whitelisting.end_at}
                            title={'Whitelist ending'}
                          />
                        )}

                      <div className="collection__body mt-4 mb-4">
                        <h4 className={'grey-text'}>About</h4>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: collection.info.description
                          }}
                        />
                        {collection?.info.bio?.text && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: collection.info.bio?.text
                            }}
                          />
                        )}
                      </div>

                      <AccountCheckWrapper
                        fallback={
                          <SiteButton
                            onClick={onClickWallet}
                            color={'YELLOW'}
                            size={'large'}
                          >
                            Connect Wallet
                          </SiteButton>
                        }
                      >
                        {isCollectionNetworkSelected ? (
                          isWhitelistTime && (
                            <WhitelistBlock
                              collection={collection}
                              statusMessage={statusMessage}
                              whitelistLoading={whitelistLoading}
                              whitelistStatus={
                                whitelistStatus as WhitelistStatus
                              }
                            />
                          )
                        ) : (
                          <div className={'mt-5'}>
                            <WrongNetworkBlock type={'Whitelist'} />
                          </div>
                        )}
                      </AccountCheckWrapper>

                      <div className="nft-token__social-share">
                        <ShareWithBlock text={`NFT from ${collection.name}`} />
                      </div>

                      {/*<SocialLinks socialLinks={collection.social_links} />*/}
                    </Col>
                  </Row>
                </Container>
              </section>
            </div>
          ) : (
            <AccountCheckWrapper>
              {isDefaultNetworkSelected ? (
                <div className={'page nfts-page'}>
                  <MyCollectionBanner
                    bannerSlides={bannerSlides}
                    // eslint-disable-next-line
                    title={collection.name}
                  />

                  <Container>
                    <NFTListBlock collection={collection} />
                  </Container>
                </div>
              ) : (
                <WrongNetworkBlock type={'NFT list'} />
              )}
            </AccountCheckWrapper>
          ))}
      </LoadingWrap>
    </main>
  )
}

export default CollectionDetails

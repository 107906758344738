import React, { useEffect, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'

import {
  EmptyNftListTile,
  LoadingWrap,
  NftList,
  RoundButton,
  SiteButton
} from '@components/index'

import { useNFT } from '@contracts/hooks/NFT/useNFT'

interface INFTListBlockProps {}

export const MyCollectionNftListBlock = ({}: INFTListBlockProps) => {
  const { account } = useWeb3React()

  const {
    userNFTTokens,
    getUserTokens,
    loadingUserTokens,
    totalUserNFTAmount
  } = useNFT('')

  const isFirstLoad = !userNFTTokens?.length

  const memorizedNFTList = useMemo(
    () => <NftList NFTArray={userNFTTokens!} />,
    [userNFTTokens]
  )

  return (
    <LoadingWrap loading={loadingUserTokens && isFirstLoad}>
      {userNFTTokens?.length ? (
        <>
          {memorizedNFTList}
          <LoadingWrap loading={loadingUserTokens && !isFirstLoad}>
            {totalUserNFTAmount > userNFTTokens.length && (
              <div className={'d-flex mt-4 justify-content-center mb-5'}>
                <SiteButton
                  bordered
                  color={'DARK'}
                  size={'large'}
                  disabled={loadingUserTokens}
                  onClick={() => getUserTokens(account)}
                >
                  Show more
                </SiteButton>
              </div>
            )}
          </LoadingWrap>
        </>
      ) : (
        <EmptyNftListTile text="Your NFT's will be placed there" />
      )}
    </LoadingWrap>
  )
}

import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import { COLLECTION } from '@/mocks/collections'
import { MARKET_ITEMS } from '@/mocks/market'

import {
  IMarketItem,
  MarketList,
  PriceSort,
  RoundButton,
  SaleType,
  SearchBar,
  StatusFilter
} from '@components/index'

import { normalizeStatus } from '@api/assetSale/mapping'

import { WhitelistStatus } from '../../types'

import './Market.scss'

export const Market = () => {
  const [items, setItems] = useState<IMarketItem[]>([])
  const [sort, setSort] = useState<'asc' | 'desc'>('asc')
  const [filter, setFilter] = useState<string>('All')

  useEffect(() => {
    setItems(MARKET_ITEMS)
  }, [])

  useEffect(() => {
    const getPrice = (item: any) => {
      if ('tokenAmount' in item.sale_details) {
        return item.sale_details.tokenAmount
      } else {
        return item.sale_details.starting_bid
      }
    }
    if (sort === 'asc') {
      setItems((items) => [...items].sort((a, b) => getPrice(a) - getPrice(b)))
    }

    if (sort === 'desc') {
      setItems((items) => [...items].sort((a, b) => getPrice(b) - getPrice(a)))
    }
  }, [sort])

  useEffect(() => {
    if (filter === 'All') {
      setItems(MARKET_ITEMS)
    } else {
      const filteredArray = MARKET_ITEMS.filter((item) => {
        const statusMessage = normalizeStatus({
          saleType: item.sale_type as SaleType,
          status: item.sale_details.status,
          publicPresaleStart: item.sale_details.starts_at,
          publicPresaleEnd: item.sale_details.end_at,
          whitelistEnd: COLLECTION.whitelisting.starts_at,
          whitelistStart: COLLECTION.whitelisting.end_at
        })
        return statusMessage === filter
      })
      setItems(filteredArray)
    }
  }, [filter])

  return (
    <main className={'page market-page'}>
      <Container>
        <Row>
          <Col lg={3}>
            <h1 className="subtitle">Marketplace</h1>
            <StatusFilter statuses={WhitelistStatus} setFilter={setFilter} />
            <PriceSort setSort={setSort} />
          </Col>

          <Col lg={9}>
            <Row className={'mb-4'}>
              <SearchBar />
            </Row>

            <MarketList items={items} />
            <div className={'d-flex mt-4 justify-content-center'}>
              <RoundButton color={'DARK'}>Load more</RoundButton>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  )
}

export default Market

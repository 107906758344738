import React, { useEffect, useMemo } from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'

import { ArtList, LoadingWrap } from '@components/index'

import { useCollectionsState } from '../../hooks/useCollections'

import './Art.scss'

type CollectionsStatus = 'upcoming' | 'past'

interface IParams {
  collections_status?: CollectionsStatus
}

export const Art = () => {
  const params = useParams<IParams>()
  const collectionStatus = params['collections_status']
  const {
    collections,
    upcomingCollections,
    completedCollections,
    getCollections,
    loading
  } = useCollectionsState()

  useEffect(() => {
    getCollections()
  }, [])

  const collectionsList = useMemo(() => {
    if (collectionStatus === 'upcoming') {
      return upcomingCollections
    }
    if (collectionStatus === 'past') {
      return completedCollections
    }
    return collections
  }, [collectionStatus, upcomingCollections, completedCollections, collections])

  return (
    <main className={'page pb-5 collections-page'}>
      <div className="page-top-banner">
        <Container>
          <h1 className={'title'}>NFT Collections</h1>
          <div className="under-title">Explore the collections</div>
        </Container>
      </div>

      <div>
        <LoadingWrap loading={loading}>
          {collectionsList?.length ? (
            <>
              <ArtList collections={collectionsList} />
            </>
          ) : (
            <Container>
              <h2 className="title mt-5 text-center">
                Not any collections here...
              </h2>
            </Container>
          )}
        </LoadingWrap>
      </div>
    </main>
  )
}

export default Art

import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'

import { LoadingWrap, MonthComponent } from '@/components'

import { IEventData, IMonthData } from '@components/Calendar/types'

import { useCalendarData } from '@hooks/useCalendarData'

import { groupDateByMonth } from '@utils/date'

import './Calendar.scss'

export const Calendar = () => {
  const [calendarData, setCalendarData] = useState<IMonthData<IEventData>>({})
  const { calendarEvents, loading } = useCalendarData()

  useEffect(() => {
    const mappedData = groupDateByMonth<IEventData>(calendarEvents)
    setCalendarData(mappedData)
  }, [calendarEvents])

  return (
    <main className={'page calendar-page'}>
      <div className="page-top-banner">
        <Container>
          <h1 className={'title'}>Upcoming Drops</h1>
        </Container>
      </div>
      <Container>
        <LoadingWrap loading={loading}>
          {Object.keys(calendarData).map((key: string) => (
            <MonthComponent key={key} events={calendarData[key]} name={key} />
          ))}
        </LoadingWrap>
      </Container>
    </main>
  )
}

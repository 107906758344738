import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Formik, FormikHelpers, useFormik } from 'formik'

import { launchProject } from '@/api'
import { ApprovalSteps } from '@/components'

import { StepPersonal } from '@components/layout/LaunchModal/LaunchForm/StepPersonal'
import { StepProject } from '@components/layout/LaunchModal/LaunchForm/StepProject'
import { StepSuccess } from '@components/layout/LaunchModal/LaunchForm/StepSuccess'
import { getValidationSchema } from '@components/layout/LaunchModal/LaunchForm/validation'

import './LaunchForm.scss'

interface ILaunchFormProps {
  setLaunchModalShow: Dispatch<SetStateAction<boolean>>
}

type FormStep = 'personal' | 'project' | 'success'

export enum ProjectTypesEnum {
  NFT_LAUNCH = 'NFT Launch',
  TOKEN_LAUNCH = 'Token Launch',
  DEX_LISTING = 'DEX Listing'
}

export interface LaunchFormValues {
  first_name: string
  last_name: string
  email: string
  telegram: string | null
  project_type: ProjectTypesEnum
  project_name: string
  project_website: string
  project_telegram: string
  project_twitter: string | null
}

export const LaunchForm = ({
  setLaunchModalShow
}: ILaunchFormProps): JSX.Element => {
  const [formStep, setFormStep] = useState<FormStep>('personal')
  const [loading, setLoading] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)

  const initialValues: LaunchFormValues = {
    first_name: '',
    last_name: '',
    email: '',
    telegram: '',
    project_type: ProjectTypesEnum.NFT_LAUNCH,
    project_name: '',
    project_website: '',
    project_telegram: '',
    project_twitter: ''
  }

  const onSubmitHandler = async (
    values: LaunchFormValues,
    formikHelpers: FormikHelpers<LaunchFormValues>
  ) => {
    if (loading) {
      return
    }
    setFailed(false)
    setLoading(true)
    try {
      const status = await launchProject(values)
      setLoading(false)
      if (status === true) {
        formikHelpers.resetForm({ values: { ...initialValues } })
        setFormStep('success')
      } else {
        setFailed(true)
      }
    } catch (e) {
      setLoading(false)
      setFailed(true)
    }
  }

  const formikProps = {
    validateOnChange: true,
    validateOnMount: true,
    validationSchema: getValidationSchema(),
    onSubmit: onSubmitHandler,
    initialValues
  }

  const continueHandler = useCallback(() => {
    setFormStep('project')
  }, [])

  const finishHandler = useCallback(() => {
    setLaunchModalShow(false)
    setFormStep('personal')
  }, [])

  const stepsMap: Record<FormStep, JSX.Element> = {
    personal: <StepPersonal continueHandler={continueHandler} />,
    project: <StepProject loading={loading} failed={failed} />,
    success: <StepSuccess finishHandler={finishHandler} />
  }

  return (
    <div className="launch-form">
      {formStep !== 'success' && (
        <Modal.Title as="div" className={'d-block'}>
          <div className="modal-title">Launch with us</div>
          <div className="modal-under-title">
            Interested in being the next hot project on Dogechain? Fill out the
            launch application and mission control will get back to you shortly
          </div>
        </Modal.Title>
      )}
      <Formik {...formikProps}>
        {(props) => (
          <Form noValidate onSubmit={props.handleSubmit}>
            {stepsMap[formStep]}
          </Form>
        )}
      </Formik>

      {formStep !== 'success' && (
        <ApprovalSteps fillingCondition={formStep === 'project'} />
      )}
    </div>
  )
}

import React from 'react'
import { Image } from 'react-bootstrap'
import { generatePath, Link } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'

import CollectionPlaceholder from '@assets/NFT/NFT-placeholder.svg'

import { IPreviewCollection } from '../types'

import './CollectionCard.scss'

interface ICollectionCardProps extends IPreviewCollection {
  withDate?: true
  image: string
  collectionName: string
}

export const CollectionCard = ({
  id,
  name,
  image,
  collectionName
}: ICollectionCardProps) => {
  return (
    <Link
      to={generatePath(RoutesPaths.COLLECTION_DETAILS, { collection_id: id })}
      className={'tile collection-card'}
    >
      <div className={'collection-card__img'}>
        <Image src={image || CollectionPlaceholder} />
      </div>

      <div className="collection-card__body">
        <div className={''}>
          <div className="collection-card__title">{name}</div>
          <div className="collection-card__name mt-3">{collectionName}</div>
        </div>

        <div className="collection-card__price">1.5 MW</div>
      </div>
    </Link>
  )
}

import React, { Dispatch } from 'react'
import { Modal } from 'react-bootstrap'

import {
  DynamicImage,
  NormalizedCollectionInfo,
  SocialLinks,
  WhitelistForm
} from '@/components'
import { ISocialLink, SOCIAL_LINKS } from '@/constants'

import './WhitelistModal.scss'

interface IWhitelistModalProps {
  show: boolean
  collection: NormalizedCollectionInfo
  setShow: Dispatch<boolean>
}

interface IStayConnectedProps {
  socialLinks: ISocialLink[]
}

const StayConnected = ({ socialLinks }: IStayConnectedProps) => (
  <div className={'stay-connected'}>
    <div className="stay-connected__title">Stay connected</div>

    <SocialLinks socialLinks={socialLinks} />
  </div>
)

export const WhitelistModal = ({
  show,
  collection,
  setShow
}: IWhitelistModalProps): JSX.Element => {
  return (
    <Modal
      className="whitelist-modal"
      show={show}
      onHide={() => setShow(false)}
      onBackdropClick={() => setShow(false)}
    >
      <button
        className={'whitelist-modal__close'}
        onClick={() => setShow(false)}
      >
        <DynamicImage path={'close.svg'} />
      </button>

      <WhitelistForm collection={collection} isInModal={true} />

      <StayConnected socialLinks={SOCIAL_LINKS} />
    </Modal>
  )
}

import { useMemo } from 'react'
import { getTokenContract } from '@firestarter-private/firestarter-library/lib/hooks/useContracts/getters/eth'
import { Token } from '@solana/spl-token'
import { useWeb3React } from '@web3-react/core'
import { Contract } from 'web3-eth-contract'

import { ContractAddress } from '@components/index'

import { useWeb3 } from '@hooks/useWeb3'

export function useTokenContract(
  address?: ContractAddress
): Token | Contract | null {
  const { chainId } = useWeb3React()
  const web3 = useWeb3()

  return useMemo(
    () =>
      chainId ? getTokenContract(chainId.toString(), address, web3) : null,
    [chainId, address, web3, getTokenContract]
  )
}

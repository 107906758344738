import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'
import classNames from 'classnames'
import { format, isFuture, isPast } from 'date-fns'

import { IWhitelistPhase, TokenInfo } from '@/components'

import { useDecimals } from '@contracts/hooks/useDecimals'

import { useGetTokenPrice } from '@hooks/useGetTokenPrice'
import { useMediaDimensions } from '@hooks/useMediaDimensions'

import { balanceToNumber } from '@utils/currency'

import './PhasesList.scss'

interface IPhasesListProps {
  phases: IWhitelistPhase[]
  fundToken: TokenInfo
  collectionId?: string
}

export const PhasesList = ({
  phases = [],
  collectionId,
  fundToken
}: IPhasesListProps) => {
  const isActivePhase = useCallback(
    (phase: IWhitelistPhase) =>
      isPast(new Date(phase.presale_starts_at)) &&
      isFuture(new Date(phase.presale_ends_at)),
    []
  )
  const fundTokenDecimals = useDecimals(fundToken.address)
  const [tokenPrice, calculateAmountInUsd] = useGetTokenPrice(fundToken.name)
  const { md } = useMediaDimensions()

  const sortedPhases: Array<IWhitelistPhase> = phases.sort((a, b) => {
    return +new Date(a.presale_starts_at) - +new Date(b.presale_starts_at)
  })

  const history = useHistory()
  return md ? (
    <div className={'phase-list'}>
      <div className={'phase-row phase-row--head'}>
        <div className="phase-row__col">Phase</div>
        <div className="phase-row__col">Opens</div>
        <div className="phase-row__col">Closes</div>
        <div className="phase-row__col">Price</div>
      </div>
      {sortedPhases.map((phase) => (
        <div
          key={phase.name}
          onClick={(e) => {
            if (!collectionId) return
            history.push(
              generatePath(RoutesPaths.COLLECTION_DETAILS, {
                collection_id: collectionId
              })
            )
          }}
          className={classNames([
            'phase-row',
            { 'phase-row--active': isActivePhase(phase) },
            { 'phase-row--link': collectionId }
          ])}
        >
          <div className="phase-row__col">{phase.name}</div>
          <div className="phase-row__col">
            {isActivePhase(phase) ? (
              'Current Phase'
            ) : (
              <>
                {format(new Date(phase.presale_starts_at), 'yyyy-M-d')}{' '}
                <span className={'phase-row__time'}>
                  {format(new Date(phase.presale_starts_at), 'H:mm:ss')}
                </span>
              </>
            )}
          </div>
          <div className="phase-row__col">
            {format(new Date(phase.presale_ends_at), 'yyyy-M-d')}{' '}
            <span className={'phase-row__time'}>
              {format(new Date(phase.presale_ends_at), 'H:mm:ss')}
            </span>
          </div>
          <div className="phase-row__col">
            <div>
              {balanceToNumber(phase.price, fundTokenDecimals)} {fundToken.name}
            </div>
            <span className={'t-small'}>
              ($
              {calculateAmountInUsd(
                balanceToNumber(phase.price, fundTokenDecimals)
              ).toFixed(2)}
              )
            </span>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div>
      {sortedPhases.map((phase) => (
        <div
          key={phase.name}
          className={classNames([
            'phase-item',
            { 'phase-item--active': isActivePhase(phase) }
          ])}
        >
          <div className="phase-item__name">{phase.name}</div>
          <div className="phase-item__row">
            <div className="phase-item__attr-name">Opens</div>

            <div className="phase-item__value">
              {isActivePhase(phase) ? (
                'Current Phase'
              ) : (
                <>
                  {format(new Date(phase.presale_starts_at), 'yyyy-M-d')}{' '}
                  <span className={'phase-row__time'}>
                    {format(new Date(phase.presale_starts_at), 'H:mm:ss')}
                  </span>
                </>
              )}
            </div>
          </div>

          <div className="phase-item__row">
            <div className="phase-item__attr-name">Closes</div>

            <div className="phase-item__value">
              <>
                {format(new Date(phase.presale_ends_at), 'yyyy-M-d')}{' '}
                <span className={'phase-row__time'}>
                  {format(new Date(phase.presale_ends_at), 'H:mm:ss')}
                </span>
              </>
            </div>
          </div>

          <div className="phase-item__row">
            <div className="phase-item__attr-name">Price</div>

            <div className="phase-item__value">
              <div>
                {balanceToNumber(phase.price, fundTokenDecimals)}{' '}
                {fundToken.name}
              </div>
              <span className={'t-small'}>
                ($
                {calculateAmountInUsd(
                  balanceToNumber(phase.price, fundTokenDecimals)
                ).toFixed(2)}
                )
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

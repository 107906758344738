import React from 'react'
import { Image } from 'react-bootstrap'
import { generatePath } from 'react-router-dom'
import { RoutesPaths } from '@router/constants'

import { CollectionAssets, DynamicImage, SiteButton } from '@/components'

import { IPreviewCollection } from '@components/Collections/types'

import { useMediaDimensions } from '@hooks/useMediaDimensions'

import NFTCardImage from '@assets/NFT/nft-card.gif'
import CollectionPlaceholder from '@assets/NFT/NFT-placeholder.png'
import GalleryLogo from '@assets/PD-logo.svg'

import './ArtCard.scss'

export interface INFTCardData {
  image: string
  id: number
  cost: number
  name: string
}

interface IArtCardProps extends IPreviewCollection {
  description: string
  nfts_list: INFTCardData[]
  assets: CollectionAssets
}

export const ArtCard = ({
  id,
  name,
  assets,
  description,
  nfts_list
}: IArtCardProps): JSX.Element => {
  const { md } = useMediaDimensions()
  return (
    <div className="art-card">
      <div className="art-card__banner">
        <figure className="art-card__background">
          <Image src={assets.banner_image_url} />
        </figure>

        <div className="art-card__logo">
          <Image src={GalleryLogo} alt={'known gallery logo'} />
        </div>

        <div className="art-card__body">
          <h4 className="art-card__title">{name}</h4>

          <div className="art-card__info">{description}</div>
        </div>
      </div>

      {md ? (
        <div className="art-card__slider">
          {nfts_list.map(({ id, image, name, cost }) => (
            <NFTCard key={id} id={id} name={name} image={image} cost={cost} />
          ))}

          <div className="art-card__over-layer">
            <SiteButton
              color={'DARK'}
              size={'large'}
              bordered
              to={generatePath(RoutesPaths.COLLECTION_DETAILS, {
                collection_id: id
              })}
            >
              Explore
              <DynamicImage path={'right_arrow.svg'} alt={'arrow'} />
            </SiteButton>
          </div>
        </div>
      ) : (
        <SiteButton
          color={'DARK'}
          size={'large'}
          bordered
          to={generatePath(RoutesPaths.COLLECTION_DETAILS, {
            collection_id: id
          })}
        >
          Explore
          <DynamicImage path={'right_arrow.svg'} alt={'arrow'} />
        </SiteButton>
      )}
    </div>
  )
}

interface INFTCard {
  image: string
  id: number
  cost: number
  name: string
}

const NFTCard = ({ id, image, cost, name }: INFTCard) => {
  return (
    <div className="tile collection-card">
      <div className={'collection-card__img'}>
        <Image src={image || CollectionPlaceholder} />
      </div>

      <div className="collection-card__body">
        <div className={''}>
          <div className="collection-card__title">{name}</div>
          <div className="collection-card__name mt-3">Collection name</div>
        </div>

        <div className="collection-card__price">{cost} MW</div>
      </div>
    </div>
  )
}

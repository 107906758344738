import React from 'react'

import { DynamicImage, SiteButton } from '@/components'

import './StepSuccess.scss'

interface IStepSuccessProps {
  finishHandler: () => void
}

export const StepSuccess = ({
  finishHandler
}: IStepSuccessProps): JSX.Element => {
  return (
    <div className="step-success">
      <div className="step-success__image">
        <DynamicImage path={'success-image.svg'} />
      </div>

      <div className="step-success__title">Thanks for inquiring!</div>
      <div className="step-success__description">We will reach out shortly</div>
      <SiteButton color={'YELLOW'} size={'large'} onClick={finishHandler}>
        GOT IT
      </SiteButton>
    </div>
  )
}

import { gql } from '@apollo/client'

export const getPhaseSoldAmountQuery = gql`
  query getPhaseSoldAmount($collection: String!) {
    phases(where: { collection: $collection }) {
      soldAmount
      phaseId
    }
  }
`

export const getUserNfts = gql`
  query getUserNfts($first: Int!, $skip: Int!, $owner: String!) {
    tokens(first: $first, skip: $skip, where: { owner: $owner }) {
      id
      tokenId
      phase {
        id
        collection
        price
      }
      owner
    }
  }
`

export const getCollectionNfts = gql`
  query getCollectionNfts($first: Int!, $skip: Int!, $phases: [String]!) {
    tokens(first: $first, skip: $skip, where: { phase_in: $phases }) {
      id
      tokenId
      phase {
        id
        collection
        price
      }
      owner
    }
  }
`

export const getCollectionPhases = gql`
  query getCollectionPhases($first: Int!, $skip: Int!, $collection: String!) {
    phases(first: $first, skip: $skip, where: { collection: $collection }) {
      id
      phaseId
      type
      collection
      tokens {
        id
        tokenId
        owner
        phase {
          price
        }
      }
    }
  }
`

export const getNftToken = gql`
  query getNftToken($id: String!) {
    tokens(first: 1, where: { id: $id }) {
      id
      tokenId
      phase {
        id
        collection
        price
      }
      owner
    }
  }
`

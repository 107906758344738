import React, { useEffect, useMemo, useState } from 'react'
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap'
import {
  generatePath,
  Link,
  useLocation,
  useRouteMatch
} from 'react-router-dom'
import { RoutesPaths } from '@router/constants'
import { useWeb3React } from '@web3-react/core'
import classNames from 'classnames'

import { Accordion, DynamicImage, SocialLinks } from '@/components'
import { SOCIAL_LINKS } from '@/constants'

import { Copiable } from '@components/common'
import { SiteButton } from '@components/common/SiteButton/SiteButton'
import { LaunchModal } from '@components/layout/LaunchModal'

import { useMediaDimensions } from '@hooks/useMediaDimensions'
import { useWalletConnect } from '@hooks/useWalletConnect'
import useWindowScroll from '@hooks/useWindowScroll'

import { shorterETHAddress } from '@utils/string'

import logo from '@assets/logo-dogechain.png'

import './Header.scss'

interface Props {}

const COLLECTION_ID = process.env.REACT_APP_NFT_COLLECTION_ID || ''
const DEX_URL = process.env.REACT_APP_DEX_PROJECT_URL || ''
const TOKEN_LAUNCHPAD_URL =
  process.env.REACT_APP_TOKEN_LAUNCHPAD_PROJECT_URL || ''
const isProduction = process.env.NODE_ENV !== 'development'

const BRIDGE_URL = 'https://app.multichain.org/#/router'

export const Header = (props: Props) => {
  const { onClickWallet } = useWalletConnect()
  const { account, active } = useWeb3React()
  const { lg } = useMediaDimensions()
  const location = useLocation()
  const [showLaunchModal, setShowLaunchModal] = useState<boolean>(false)

  const accountPath = useRouteMatch(RoutesPaths.ACCOUNT.MY_COLLECTIONS)
  const aboutUsPath = useRouteMatch(RoutesPaths.ABOUT_US)
  const calendarPath = useRouteMatch(RoutesPaths.CALENDAR)
  const artPath = useRouteMatch(RoutesPaths.ART)
  const collectionsPath = useRouteMatch(RoutesPaths.COLLECTION_DETAILS)
  const roadmapPath = useRouteMatch(RoutesPaths.ROADMAP)
  const marketPath = useRouteMatch(RoutesPaths.MARKET)
  const [key, setKey] = useState<number>(+new Date())

  const isArtActive = useMemo(
    () =>
      accountPath?.isExact ||
      artPath?.isExact ||
      collectionsPath?.isExact ||
      roadmapPath?.isExact,
    [accountPath, artPath, collectionsPath, roadmapPath]
  )

  const isCommunityActive = useMemo(() => calendarPath?.isExact, [calendarPath])

  const { y } = useWindowScroll()

  useEffect(() => {
    setKey(+new Date())
  }, [location.pathname])

  const RightActionButtonGroup = () => {
    return (
      <div className={'d-inline-flex right-actions-wrapper gap-2'}>
        {!lg && <SocialLinks socialLinks={SOCIAL_LINKS} />}
        <div className="nav-dropdown">
          <SiteButton
            color={'DARK'}
            className={'right-actions-wrapper__launch-link'}
            onClick={() => setShowLaunchModal((state) => !state)}
          >
            <DynamicImage path={'menu-icons/launch.svg'} />
            Launch with us
          </SiteButton>
        </div>
        {!isProduction && lg && (
          <Dropdown className="nav-dropdown apps-dropdown">
            <Dropdown.Toggle as={SiteButton}>
              <DynamicImage path={'menu-icons/apps.svg'} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-dropdown__menu">
              <div className={'apps-dropdown__title'}>Our Apps</div>

              <Dropdown.Item
                as={'a'}
                href={DEX_URL}
                target={'_blank'}
                className="nav-dropdown__item apps-item"
              >
                <div className="apps-item__ico">
                  <DynamicImage path={'menu-icons/dex.svg'} />
                </div>

                <div className="apps-item__body">
                  <div className="apps-item__name">DEX</div>
                  <div className="apps-item__description">Swap and Earn</div>
                </div>
              </Dropdown.Item>

              <Dropdown.Item
                as={'a'}
                href={TOKEN_LAUNCHPAD_URL}
                target={'_blank'}
                className="nav-dropdown__item apps-item"
              >
                <div className="apps-item__ico">
                  <DynamicImage path={'menu-icons/token-launchpad.svg'} />
                </div>

                <div className="apps-item__body">
                  <div className="apps-item__name">Token Launchpad</div>
                  <div className="apps-item__description">
                    A launchpad for initial metaverse offerings
                  </div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <div className="header__web3-actions">
          <span
            className={classNames({
              'header__connect-wallet': true,
              active: active
            })}
            onClick={onClickWallet}
          >
            {active ? (
              <div className={'d-flex g-5 align-items-center'}>
                <Copiable text={account!}>
                  {shorterETHAddress(account!)}
                  <span>
                    <DynamicImage path={'copy.svg'} />
                  </span>
                </Copiable>
              </div>
            ) : (
              'Connect Wallet'
            )}
          </span>
        </div>
      </div>
    )
  }

  const MemorizedRightActionButtonGroup = useMemo(
    () => <RightActionButtonGroup />,
    [account, active]
  )

  return (
    <Container
      as="header"
      fluid
      className={classNames([
        {
          'header-fixed-bg': y > 10
        }
      ])}
    >
      <Navbar expand={'lg'} key={key} className="header__navbar-menu">
        <Navbar.Brand>
          <Link to={RoutesPaths.MAIN}>
            <img src={logo} alt="Logo" className="d-inline-block align-top" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          {lg ? (
            <>
              <Dropdown
                className={classNames([
                  'nav-dropdown',
                  { 'nav-dropdown--active': isArtActive }
                ])}
              >
                <Dropdown.Toggle as={SiteButton}>Art</Dropdown.Toggle>
                <Dropdown.Menu className="nav-dropdown__menu">
                  <Dropdown.Item
                    as={Link}
                    active={artPath?.isExact}
                    to={generatePath(RoutesPaths.ART)}
                    className="nav-dropdown__item"
                  >
                    NFT Collections
                  </Dropdown.Item>

                  <Dropdown.Item
                    as={Link}
                    active={accountPath?.isExact}
                    to={generatePath(RoutesPaths.ACCOUNT.MY_COLLECTIONS)}
                    className="nav-dropdown__item"
                  >
                    My Collection
                  </Dropdown.Item>

                  <Dropdown.Item
                    as={Link}
                    to={generatePath(RoutesPaths.ART, {
                      collections_status: 'upcoming'
                    })}
                    className="nav-dropdown__item"
                  >
                    Upcoming Drops
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown
                className={classNames([
                  'nav-dropdown',
                  { 'nav-dropdown--active': isCommunityActive }
                ])}
              >
                <Dropdown.Toggle as={SiteButton}>Community</Dropdown.Toggle>
                <Dropdown.Menu className="nav-dropdown__menu">
                  <Dropdown.Item
                    as={Link}
                    active={calendarPath?.isExact}
                    to={RoutesPaths.CALENDAR}
                    className="nav-dropdown__item"
                  >
                    Calendar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            <MobileMenu />
          )}
          {!lg && MemorizedRightActionButtonGroup}
        </Navbar.Collapse>

        {lg && MemorizedRightActionButtonGroup}
      </Navbar>

      <LaunchModal show={showLaunchModal} setShow={setShowLaunchModal} />
    </Container>
  )
}

const MobileMenu = () => {
  return (
    <div className={'mobile-accordion'}>
      <Accordion
        type={'header'}
        items={[
          {
            title: 'Art',
            body: (
              <>
                <Link
                  to={generatePath(RoutesPaths.ART)}
                  className="nav-dropdown__item"
                >
                  NFT Collections
                </Link>
                <Link
                  to={generatePath(RoutesPaths.ACCOUNT.MY_COLLECTIONS)}
                  className="nav-dropdown__item"
                >
                  My collection
                </Link>
                <Link to={RoutesPaths.ROADMAP} className="nav-dropdown__item">
                  Roadmap
                </Link>
                <Link
                  to={generatePath(RoutesPaths.ART, {
                    collections_status: 'upcoming'
                  })}
                  className="nav-dropdown__item"
                >
                  Upcoming Drops
                </Link>
                <Link
                  to={generatePath(RoutesPaths.ART, {
                    collections_status: 'past'
                  })}
                  className="nav-dropdown__item"
                >
                  Past Drops
                </Link>
              </>
            )
          },
          {
            title: 'Community',
            body: (
              <>
                <Link to={RoutesPaths.CALENDAR} className="nav-dropdown__item">
                  Calendar
                </Link>
              </>
            )
          }
        ]}
      />
    </div>
  )
}

import React, { useEffect, useRef } from 'react'
import { useWeb3React } from '@web3-react/core'
import classNames from 'classnames'

import { DynamicImage, SiteButton } from '@/components'

import { Copiable } from '@components/common'

import { useWalletConnect } from '@hooks/useWalletConnect'
import useWindowScroll from '@hooks/useWindowScroll'

import { shorterETHAddress } from '@utils/string'

import './MobileConnectWalletButton.scss'

interface IMobileConnectWalletButtonProps {}

export const MobileConnectWalletButton =
  ({}: IMobileConnectWalletButtonProps): JSX.Element => {
    const { onClickWallet } = useWalletConnect()
    const { account, active } = useWeb3React()
    const { y } = useWindowScroll()
    const prevY = useRef(0)

    useEffect(() => {
      prevY.current = y
    }, [y])

    const isScrollToTop = prevY.current - y >= 0

    return (
      <div
        className={classNames({
          'mobile-connect-wallet-button': true,
          'mobile-connect-wallet-button__show': isScrollToTop
        })}
      >
        <span
          className={classNames({
            'mobile-connect-wallet-button__button': true,
            active: active
          })}
          onClick={active ? undefined : onClickWallet}
        >
          {active ? (
            <div className={'d-flex g-5 align-items-center'}>
              <Copiable text={account!}>
                {shorterETHAddress(account!)}
                <span>
                  <DynamicImage path={'copy.svg'} />
                </span>
              </Copiable>
            </div>
          ) : (
            'Connect Wallet'
          )}
        </span>
      </div>
    )
  }
